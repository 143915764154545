import React from "react";
import { Language } from "../models/schema";

export interface LanguageContextSchema {
  selectedLanguage: string;
  availableLanguages: Language[];
  setLanguage: (lang: string) => void;
}

export const LanguageContext = React.createContext<LanguageContextSchema>(
  {} as LanguageContextSchema
);
