import { Box, Grid, Typography } from "@mui/material";
import { Maybe } from "graphql/jsutils/Maybe";
import { useContext } from "react";
import { ContentfulContext } from "../../context/ContentfulContext";
import {
  PartnerForm,
  PartnerFormDiamondDescription,
  PartnerFormEliteDescription,
  PartnerFormGoldDescription,
  PartnerFormSilverDescription
} from "../../models/schema";
import { colors } from "../../theme/colors";
import RenderContent from "../utils/RenderContent";

export const DiscountInfo = () => {
  const { content } = useContext(ContentfulContext);

  const {
    silverTitle,
    silverDiscount,
    silverDescription,
    goldTitle,
    goldDiscount,
    goldDescription,
    diamondTitle,
    diamondDiscount,
    diamondDescription,
    eliteTitle,
    eliteDiscount,
    eliteDescription
  } = content?.becomePartner as PartnerForm;

  const discounts: Array<{
    title: Maybe<string> | undefined;
    discount: Maybe<string> | undefined;
    description:
      | Maybe<PartnerFormSilverDescription>
      | Maybe<PartnerFormGoldDescription>
      | Maybe<PartnerFormDiamondDescription>
      | Maybe<PartnerFormEliteDescription>;
    background: string;
  }> = [
    {
      title: silverTitle,
      discount: silverDiscount,
      description: silverDescription,
      background: "linear-gradient(135deg, #b3b3b3, #ffffff, #b3b3b3)"
    },
    {
      title: goldTitle,
      discount: goldDiscount,
      description: goldDescription,
      background: "linear-gradient(135deg, #BF953F, #ffeebb, #B38728)"
    },
    {
      title: diamondTitle,
      discount: diamondDiscount,
      description: diamondDescription,
      background: "linear-gradient(135deg, #61b9d7, #ddf6ff, #61b9d7)"
    },
    {
      title: eliteTitle,
      discount: eliteDiscount,
      description: eliteDescription,
      background: "linear-gradient(135deg, #494949, #adadad, #494949)"
    }
  ];

  return (
    <Grid container spacing={6}>
      {discounts.map((discount, i) => (
        <Grid item xs={12} md={6} xl={3} key={i}>
          <Box
            sx={(theme) => ({
              boxShadow: "0px 0px 10px 3px rgba(0, 0, 0, 0.25)",
              p: 6,
              mt: 10,
              borderRadius: theme.spacing(4)
            })}>
            <Box
              sx={(theme) => ({
                borderRadius: theme.spacing(4),
                boxShadow: "0px 0px 10px 3px rgba(0, 0, 0, 0.25)",
                mt: -15,
                mx: "auto",
                mb: 5,
                minWidth: { xs: theme.spacing(45), md: theme.spacing(50) },
                maxWidth: { xs: theme.spacing(45), md: theme.spacing(50) },
                background: colors.white
              })}>
              <Box
                sx={(theme) => ({
                  borderRadius: theme.spacing(4),
                  background: discount.background,
                  py: 3,
                  px: 10,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  mask: "linear-gradient(90deg, #000 35%, #000c, #000 65%) 100% 0%/250% 250%",
                  transition: "-webkit-mask 0.4s, transform 0.5s, --_r 0.3s, --_l 0.25s 0.25s",
                  transitionTimingFunction: "linear",
                  "&:hover": {
                    maskPosition: "0 0"
                  }
                })}>
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  textAlign="center"
                  sx={{ fontWeight: 100 }}>
                  {discount.title}
                </Typography>
                <Typography variant="h2" fontWeight="bold" textAlign="center" fontSize={50}>
                  {discount.discount}
                </Typography>
              </Box>
            </Box>

            <RenderContent content={discount.description?.json} sx={{ textAlign: "center" }} />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
