import React, { MutableRefObject } from "react";
import ReCAPTCHA from "react-google-recaptcha";

export interface RecaptchaContextSchema {
  badgeVisible: boolean;
  showBadge: () => void;
  hideBadge: () => void;
  recaptchaRef: MutableRefObject<ReCAPTCHA | null>;
  neccessaryCookiesAllowed: boolean;
  setNeccessaryCookiesAllowed: (value: boolean) => void;
}

export const RecaptchaContext = React.createContext<RecaptchaContextSchema>(
  {} as RecaptchaContextSchema
);
