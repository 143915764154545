import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Box, IconButton, InputAdornment, Paper, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { ErrorResponse } from "../../api";
import Button, { ButtonVariant } from "../../components/utils/Button";
import ButtonContainer from "../../components/utils/ButtonContainer";
import Input from "../../components/utils/Input";
import NavButton from "../../components/utils/NavButton";
import RenderContent from "../../components/utils/RenderContent";
import { routes } from "../../constants/routes";
import { ContentfulContext } from "../../context/ContentfulContext";
import { ErrorContext } from "../../context/ErrorContext";
import { UserContext } from "../../context/UserContext";
import { Account, Language } from "../../models/schema";
import { authApi } from "../../querries/api";
import { getButton, getValidationMsg } from "../../querries/getters";
import OperationResult, { OperationResultType } from "./OperationResult";

const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [result, setResult] = useState<OperationResultType>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<string>();

  const { content } = useContext(ContentfulContext);
  const { setError } = useContext(ErrorContext);
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    if (user) {
      if (location.key !== "default") {
        navigate(-1);
      } else {
        navigate(routes.home);
      }
    }
  }, [user, location, navigate]);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const { loginTitle, loginDescription, username, password, loginFailure } =
    content?.account as Account;
  const { validationErrorMessage } = content as Language;

  const buttons = content?.buttonsCollection?.items;
  const validations = content?.validationsCollection?.items;

  type Form = {
    username: string;
    password: string;
  };

  const controls: Form = {
    username: "username",
    password: "password"
  };

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm();

  const onSubmit = (data: unknown) => {
    setLoading(true);

    const { username, password } = data as Form;

    const headers = new Headers();
    headers.set("Authorization", "Basic " + btoa(username + ":" + password));

    authApi
      .login({ headers: headers })
      .then((user) => {
        setUser(user);
      })
      .catch((error) => {
        setLoading(false);

        if (error?.response?.status === 401) {
          setResult("ERROR");
          return;
        }

        error.response.json().then((json: ErrorResponse) => {
          console.error(json);

          if (json?.errorCode) {
            setErrorCode(json?.errorCode);
          }

          // Bussiness logic errors are 4xx
          if (Math.floor(error?.response?.status / 100) === 4) {
            setResult("ERROR");
            return;
          }

          // Otherwise it's an unexpected server error
          setError(error);
        });
      });
  };

  return (
    <Box>
      <Typography variant="h2" fontWeight="bold" textAlign="center" mb={{ xs: 4, md: 8 }}>
        {loginTitle}
      </Typography>
      <RenderContent content={loginDescription?.json} sx={{ mb: 8 }} />

      {!result && (
        <Paper
          component={"form"}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 4
          }}>
          <Input
            autoFocus
            required
            id={controls.username}
            label={username as string}
            error={!!errors.username}
            helperText={errors.username && (errors.username.message as string)}
            name={controls.username}
            rules={{
              required: getValidationMsg(validations, "required")
            }}
            control={control}
          />
          <Input
            required
            id={controls.password}
            label={password as string}
            type={showPassword ? "text" : "password"}
            error={!!errors.password}
            helperText={errors.password && (errors.password.message as string)}
            name={controls.password}
            rules={{
              required: getValidationMsg(validations, "required")
            }}
            control={control}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <ButtonContainer sx={{ mt: 5 }}>
            <NavButton
              route={routes.passwordReset}
              label={getButton(buttons, "forgottenPassword")}
              variant={ButtonVariant.secondary}
            />
            <Button
              loading={loading}
              onClick={handleSubmit(onSubmit)}
              label={getButton(buttons, "login")}
              variant={ButtonVariant.primary}
            />
          </ButtonContainer>
        </Paper>
      )}

      {result && (
        <OperationResult
          result={result}
          errorTitle={
            errorCode
              ? `${validationErrorMessage} ${getValidationMsg(validations, errorCode)}`
              : (loginFailure as string)
          }>
          {result === "ERROR" && (
            <Button
              onClick={() => setResult(null)}
              label={getButton(buttons, "tryAgain")}
              variant={ButtonVariant.primary}
            />
          )}
        </OperationResult>
      )}
    </Box>
  );
};

export default LoginPage;
