import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import { Alert, Box, Chip, Divider, Grid, Paper, Stack, Typography, useTheme } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Stock, StockEditionsEnum, StockSizeEnum, StockSurfacesEnum, StockUnitEnum } from "../api";
import Button, { ButtonSize, ButtonVariant } from "../components/utils/Button";
import ButtonContainer from "../components/utils/ButtonContainer";
import ImagePreview from "../components/utils/ImagePreview";
import LoadingSpinner from "../components/utils/LoadingSpinner";
import NavButton from "../components/utils/NavButton";
import RenderContent from "../components/utils/RenderContent";
import AddToCart from "../components/view/AddToCart";
import BecomePartnerBanner from "../components/view/BecomePartnerBanner";
import SelectionList from "../components/view/SelectionList";
import SpecialProductsSelection from "../components/view/SpecialProductsSelection";
import TileAttribute from "../components/view/TileAttribute";
import TileAttributes from "../components/view/TileAttributes";
import TilePreview from "../components/view/TilePreview/TilePreview";
import { routes } from "../constants/routes";
import {
  editionColorMapping,
  editionFontMapping,
  editionOptions,
  getAttributeType,
  priorityAttributes,
  sizeOptionsMap,
  sortAttributeFunction,
  StockFilters,
  thicknessOptionsMap
} from "../constants/stock-attributes";
import { ContentfulContext } from "../context/ContentfulContext";
import { ErrorContext } from "../context/ErrorContext";
import { ProductsContext } from "../context/ProductsContext";
import { UserContext } from "../context/UserContext";
import { Cart, EditionAttribute, Product, Products } from "../models/schema";
import { getButton, getMessage } from "../querries/getters";
import { colors } from "../theme/colors";
import {
  formatPrice,
  formatStock,
  getImagePath,
  getPriceWithTax,
  roundNumber
} from "../utils/utilities";

const ProductPage = () => {
  const { user } = useContext(UserContext);
  const { content } = useContext(ContentfulContext);
  const theme = useTheme();

  const {
    availableSizes,
    availableFaces,
    tileAttributes,
    package: package1,
    package2,
    package3,
    defaultPrice,
    available,
    alternativeProducts: alternativeProductsLabel,
    seriesProducts: seriesProductsLabel,
    meterUnit,
    pieceUnit,
    pieceUnit2,
    pieceUnit3,
    partnerMessage,
    notAvailableMessage,
    priceMessage,
    priceDiscountButton,
    stockMessage,
    palette,
    discontinuedAvailableMessage,
    discontinuedWarningMessage
  } = content?.product as Product;

  const { noStock } = content?.products as Products;

  const { withTax, withoutTax } = content?.cart as Cart;

  const buttons = content?.buttonsCollection?.items;
  const messages = content?.messagesCollection?.items;

  const editionAttributes = content?.editionAttributesCollection?.items as EditionAttribute[];
  const edition = useMemo(() => editionOptions(editionAttributes), [editionAttributes]);

  const { ean } = useParams();

  const { products, loading } = useContext(ProductsContext);

  const [alternativeProducts, setAlternativeProducts] = useState<Stock[]>([]);
  const [bathroomSeriesProducts, setBathroomSeriesProducts] = useState<Stock[]>([]);
  const [product, setProduct] = useState<Stock>();
  const [selectedTileImage, setSelectedTileImage] = useState<string | undefined>();

  const navigate = useNavigate();
  const location = useLocation();

  const { setError } = useContext(ErrorContext);

  const navigateBack = () => {
    if (location.key !== "default") {
      navigate(-1);
    } else {
      navigate(routes.home);
    }
  };

  useEffect(() => {
    if (ean && products?.length > 0) {
      const product = products?.find((product) => product.ean === ean);

      if (product) {
        const alternativeProducts = products.filter(
          (p) => p.ean && product?.alternativeStockEanCodes?.includes(p.ean)
        );

        const bathroomProducts = products.filter(
          (p) => p.ean && product?.bathroomSeriesStockEanCodes?.includes(p.ean)
        );

        setProduct(product);
        setAlternativeProducts(alternativeProducts ?? []);
        setBathroomSeriesProducts(bathroomProducts ?? []);
        setSelectedTileImage(product.tileFaceImages?.[0]);
      } else {
        setError(true);

        navigateBack();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ean, products]);

  if (!product) {
    return loading ? <LoadingSpinner /> : <></>;
  }

  const priceToUse = product.priceForCurrentUser ?? product.price ?? 0;
  const priceToUseWithTax = getPriceWithTax(priceToUse);

  const productQuantityUnit = roundNumber(
    (product.packagesOnStock ?? 0) * (product.amountPerPackage ?? 0)
  );

  const quantityTitle = (
    !product.packagesOnStock
      ? package3
      : product.packagesOnStock === 1
      ? package1
      : product.packagesOnStock > 1 && product.packagesOnStock < 5
      ? package2
      : package3
  )?.toLowerCase();

  const unit = product.unit === StockUnitEnum.Meter ? meterUnit : pieceUnit;

  const packageUnit = (
    product.unit === StockUnitEnum.Meter
      ? meterUnit
      : !product.amountPerPackage
      ? pieceUnit3
      : product.amountPerPackage === 1
      ? pieceUnit
      : product.amountPerPackage >= 2 && product.amountPerPackage < 5
      ? pieceUnit2
      : pieceUnit3
  )?.toLowerCase();

  const quantityUnit = (
    product.unit === StockUnitEnum.Meter
      ? meterUnit
      : !product.packagesOnStock
      ? pieceUnit3
      : product.packagesOnStock === 1
      ? pieceUnit
      : product.packagesOnStock >= 2 && product.packagesOnStock < 5
      ? pieceUnit2
      : pieceUnit3
  )?.toLowerCase();

  const isPreparing = product.editions?.includes(StockEditionsEnum.Preparing);

  const priorityProductAttributes = [
    ...(product.types ?? []),
    ...(product.designs ?? []),
    ...(product.surfaces ?? []),
    ...(product.technicalParameters ?? [])
  ]
    ?.filter((type) => priorityAttributes.includes(type))
    .sort(sortAttributeFunction);

  const hasMultipleFaces = (product.tileFaceImages?.length ?? 0) > 1;

  return (
    <Box>
      <Box
        sx={{
          maxWidth: "80%",
          position: "relative",
          alignItems: "center",
          justifyContent: "center",
          my: 3,
          mx: "auto",
          display: { xs: "flex", lg: "none" }
        }}>
        <Button
          variant={ButtonVariant.secondary}
          startIcon={<ArrowBackOutlinedIcon />}
          sx={{}}
          onClick={navigateBack}
          label={getButton(buttons, "back")}
        />
      </Box>
      <Box
        sx={{
          maxWidth: "80%",
          position: "relative",
          display: "flex",
          alignItems: "center",
          mb: 3,
          mx: "auto"
        }}>
        <Button
          variant={ButtonVariant.secondary}
          startIcon={<ArrowBackOutlinedIcon />}
          sx={{
            position: "absolute",
            left: 0,
            display: { xs: "none", lg: "flex" }
          }}
          onClick={navigateBack}
        />
        <Typography variant="h2" fontWeight="bold" textAlign="center" sx={{ width: "100%" }}>
          {product.name}
        </Typography>
      </Box>

      {product.editions && product.editions?.length > 0 && (
        <Stack
          sx={{ width: "100%", justifyContent: "center" }}
          direction="row"
          flexWrap="wrap"
          spacing={2}
          mb={4}>
          {product.editions.map((value) => (
            <Link
              key={value}
              to={`${routes.products}?${
                value === StockEditionsEnum.Discount
                  ? StockFilters.Discount
                  : value === StockEditionsEnum.Preparing
                  ? StockFilters.Preparing
                  : value === StockEditionsEnum.New
                  ? StockFilters.New
                  : StockFilters.Discontinued
              }=${true}`}
              style={{ textDecoration: "none", position: "relative" }}>
              <Chip
                sx={{
                  p: { xs: 1, md: 2 },
                  m: 1,
                  cursor: "pointer",
                  borderRadius: 10,
                  boxShadow:
                    "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
                  backgroundColor: editionColorMapping[value],
                  color: editionFontMapping[value]
                }}
                label={edition.find((opt) => opt.value === value)?.label}
              />
            </Link>
          ))}
        </Stack>
      )}

      {product.discontinuedDescription && (
        <Alert
          severity="warning"
          sx={{
            mb: 6,
            width: "100%",
            "& .MuiAlert-message": {
              width: "100%"
            }
          }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: { xs: "center", sm: "space-between" }
            }}>
            <Box sx={{ display: "inline" }}>
              <RenderContent
                content={discontinuedWarningMessage?.json}
                suffix={
                  <>
                    &nbsp;
                    {getMessage(messages, product.discontinuedDescription) ||
                      product.discontinuedDescription}
                  </>
                }
              />
            </Box>
            {product?.notDiscontinuedEanCode && (
              <NavButton
                sx={{
                  whiteSpace: { xs: "auto", sm: "nowrap" },
                  ml: { xs: 0, sm: 5 },
                  mt: { xs: 5, sm: 0 }
                }}
                label={getButton(buttons, "openCurrentOffer")}
                route={`${routes.products}/${product?.notDiscontinuedEanCode}/`}
                endIcon={<ArrowRightAltIcon />}
                variant={ButtonVariant.secondary}
              />
            )}
          </Box>
        </Alert>
      )}

      <Box sx={{ mt: { xs: 4, md: 8 }, position: "relative" }}>
        <ImagePreview
          maxWidth="80%"
          images={
            product.showcaseImages
              ?.filter((i) => !!i)
              .map((item) => ({
                original: getImagePath(item) as string
              })) ?? []
          }
        />
        {product.size || (product.alternativeSizes?.length ?? 0) > 0 ? (
          <Paper
            sx={(theme) => ({
              transform: `translateY(${theme.spacing(-4)})`,
              m: "auto",
              width: "70%",
              px: 10,
              py: 5
            })}>
            <Grid
              container
              spacing={0}
              sx={{
                display: "flex",
                flexDirection: { xs: "column", lg: "row-reverse" }
              }}>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center"
                }}>
                <Typography variant="h4" fontWeight="bold" textAlign="center" my={{ xs: 2, md: 4 }}>
                  {availableSizes}
                </Typography>

                <ButtonContainer
                  sx={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}>
                  {product.size && (
                    <NavButton
                      route={`${routes.products}?${StockFilters.Size}=${product.size}`}
                      label={sizeOptionsMap[product.size as StockSizeEnum]}
                      variant={ButtonVariant.primary}
                    />
                  )}
                  {product.alternativeSizes
                    ?.filter((alternative) => alternative.size !== product.size)
                    .map((alternative) => (
                      <NavButton
                        onClick={() => setProduct(undefined)}
                        route={`${routes.products}/${alternative.ean}`}
                        key={alternative.ean}
                        label={sizeOptionsMap[alternative.size as StockSizeEnum]}
                        variant={ButtonVariant.secondary}
                      />
                    ))}
                </ButtonContainer>
              </Grid>
            </Grid>
          </Paper>
        ) : (
          <></>
        )}
      </Box>

      <Grid container spacing={5} mt={{ xs: 0, md: 10 }} mb={{ xs: 2, md: 20 }}>
        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: { xs: 8, md: 0 }
          }}>
          <TilePreview
            src={getImagePath(selectedTileImage)}
            alt={product.name}
            size={product.size}
            glossy={
              product.surfaces &&
              (product.surfaces.includes(StockSurfacesEnum.Gloss) ||
                product.surfaces.includes(StockSurfacesEnum.HighGloss))
            }
            spatial={!product.atypicalTile}
          />
        </Grid>

        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            display: "flex",
            flexDirection: { xs: "column-reverse", lg: "column" }
          }}>
          <Box sx={{ mb: { xs: 0, lg: 10 } }}>
            <Typography
              variant="h4"
              fontWeight="bold"
              textAlign={{ xs: "center", md: "left" }}
              mb={5}>
              {tileAttributes}
            </Typography>

            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              {/* PRIORITY */}

              {priorityProductAttributes?.map((attribute) => (
                <TileAttributes
                  sx={{ width: { xs: "100%", lg: "50%" }, mb: 5 }}
                  key={attribute}
                  name={attribute}
                  type={getAttributeType(attribute)}
                />
              ))}

              {/* OTHERS */}

              {product.thickness && (
                <TileAttribute
                  sx={{ width: { xs: "100%", lg: "50%" }, mb: 5 }}
                  title={thicknessOptionsMap[product.thickness]}
                  icon={<SquareFootIcon />}
                  route={`${routes.products}?${StockFilters.Thickness}=${product.thickness}`}
                />
              )}

              {product.types
                ?.filter((type) => !priorityAttributes.includes(type))
                ?.map((attribute) => (
                  <TileAttributes
                    key={attribute}
                    name={attribute}
                    type={StockFilters.Type}
                    sx={{ width: { xs: "100%", lg: "50%" }, mb: 5 }}
                  />
                ))}

              {product.designs
                ?.filter((type) => !priorityAttributes.includes(type))
                ?.map((attribute) => (
                  <TileAttributes
                    key={attribute}
                    name={attribute}
                    type={StockFilters.Design}
                    sx={{ width: { xs: "100%", lg: "50%" }, mb: 5 }}
                  />
                ))}

              {product.surfaces
                ?.filter((type) => !priorityAttributes.includes(type))
                ?.map((attribute) => (
                  <TileAttributes
                    key={attribute}
                    name={attribute}
                    type={StockFilters.Surface}
                    sx={{ width: { xs: "100%", lg: "50%" }, mb: 5 }}
                  />
                ))}

              {/* <TileAttributes
              name={product.colors ?? []}
              type={StockFilters.Color}
              sx={{ width: { xs: "100%", lg: "50%" }, mb: 5 }}
            /> */}

              {product.technicalParameters
                ?.filter((type) => !priorityAttributes.includes(type))
                ?.map((attribute) => (
                  <TileAttributes
                    key={attribute}
                    name={attribute}
                    type={StockFilters.Technical}
                    sx={{ width: { xs: "100%", lg: "50%" }, mb: 5 }}
                  />
                ))}
            </Box>
          </Box>

          {hasMultipleFaces && (
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                width: "100%",
                my: { xs: 10, lg: 0 }
              }}>
              <Typography
                variant="h4"
                fontWeight="bold"
                textAlign={{ xs: "center", md: "left" }}
                mb={5}>
                {availableFaces}
              </Typography>
              <SelectionList
                minLengthForControls={5}
                sx={{
                  justifyContent: { xs: "center", md: "start" }
                }}
                itemsLength={product.tileFaceImages?.length ?? 0}
                scrollSize={130}>
                {product.tileFaceImages?.map((image, index) => (
                  <Box
                    component={"img"}
                    key={index}
                    src={getImagePath(image)}
                    alt={product.name}
                    onClick={() => setSelectedTileImage(image)}
                    sx={{
                      minWidth: { xs: theme.spacing(18), lg: theme.spacing(22) },
                      height: { xs: theme.spacing(18), lg: theme.spacing(22) },
                      objectFit: "cover",
                      cursor: "pointer",
                      borderRadius: 3,
                      border: image === selectedTileImage ? `2px solid ${colors.green}` : "none",
                      margin: { xs: theme.spacing(1), lg: theme.spacing(2) }
                    }}
                  />
                ))}
              </SelectionList>
            </Box>
          )}
        </Grid>
      </Grid>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          mt: { xs: 0, md: 10 },
          mb: 10
        }}>
        {product.discontinuedEanCode && (
          <Alert
            severity="info"
            sx={{
              mb: 6,
              width: "100%",
              "& .MuiAlert-message": {
                width: "100%"
              }
            }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: { xs: "center", sm: "space-between" }
              }}>
              <RenderContent content={discontinuedAvailableMessage?.json} />
              <NavButton
                sx={{
                  whiteSpace: { xs: "auto", sm: "nowrap" },
                  ml: { xs: 0, sm: 5 },
                  mt: { xs: 5, sm: 0 }
                }}
                label={getButton(buttons, "openDiscontinuedOffer")}
                route={`${routes.products}/${product?.discontinuedEanCode}/`}
                endIcon={<ArrowRightAltIcon />}
                variant={ButtonVariant.secondary}
              />
            </Box>
          </Alert>
        )}

        {user ? (
          <>
            {product.packagesOnStock && product.packagesOnStock > 0 ? (
              <AddToCart product={product} />
            ) : (
              <>
                <Alert severity="info" sx={{ width: "100%" }}>
                  <RenderContent content={notAvailableMessage?.json} />
                </Alert>

                <NavButton
                  route={routes.contact}
                  label={getButton(buttons, "contactUs")}
                  variant={ButtonVariant.primary}
                  size={ButtonSize.full}
                  sx={{
                    width: theme.spacing(40),
                    mt: 6
                  }}
                />
              </>
            )}
          </>
        ) : (
          <>
            <Alert severity="warning" sx={{ width: "100%" }}>
              <RenderContent content={partnerMessage?.json} />
            </Alert>

            <ButtonContainer sx={{ mt: 6 }}>
              <NavButton
                route={routes.login}
                label={getButton(buttons, "login")}
                variant={ButtonVariant.primary}
                size={ButtonSize.full}
                sx={{
                  width: theme.spacing(40)
                }}
              />

              <NavButton
                route={routes.partner}
                label={getButton(buttons, "becomePartner")}
                variant={ButtonVariant.secondary}
                size={ButtonSize.full}
                sx={{
                  width: theme.spacing(40)
                }}
              />
            </ButtonContainer>
          </>
        )}
      </Box>

      <Paper
        elevation={4}
        sx={{
          backgroundColor: colors.white,
          mb: 6,
          p: { xs: 3, md: 4 }
        }}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={5}>
            {product.priceForCurrentUser ? (
              <Typography
                variant="h5"
                fontWeight="bold"
                textAlign="center"
                mb={3}
                sx={{ fontWeight: 300, textDecoration: "line-through" }}>
                {formatPrice(product.price ?? 0)} / {unit}
              </Typography>
            ) : (
              <Typography
                variant="h5"
                fontWeight="bold"
                textAlign="center"
                mb={3}
                sx={{ fontWeight: 300 }}>
                {defaultPrice}
              </Typography>
            )}

            <Typography
              variant={"h2"}
              fontWeight="bold"
              textAlign="center"
              sx={{
                background: "linear-gradient(180deg, #2F2A31 0%, rgba(47, 42, 49, 0.27) 100%)",
                backgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontWeight: "bold",
                mb: 3,
                mt: product.priceForCurrentUser ? 0 : 3
              }}>
              {formatPrice(priceToUse ?? 0)} / {unit} ({withoutTax})
            </Typography>

            <Typography variant="h5" fontWeight="bold" textAlign="center" sx={{ fontWeight: 300 }}>
              {formatPrice(priceToUseWithTax ?? 0)} / {unit} ({withTax})
            </Typography>
          </Grid>

          <Grid item xs={12} md={2} sx={{ display: "flex", justifyContent: "center" }}>
            <Box sx={{ display: { xs: "block", md: "none", width: "100%" } }}>
              <Divider orientation="horizontal" />
            </Box>

            <Box
              sx={{
                display: {
                  md: "block",
                  xs: "none",
                  display: "flex",
                  justifyContent: "center"
                }
              }}>
              <Divider orientation="vertical" />
            </Box>
          </Grid>

          <Grid item xs={12} md={5}>
            <Typography
              variant="h5"
              fontWeight="bold"
              textAlign="center"
              mb={3}
              sx={{ fontWeight: 300 }}>
              {available}
            </Typography>
            {!isPreparing && (
              <>
                <Typography
                  variant={"h2"}
                  fontWeight="bold"
                  textAlign="center"
                  sx={{
                    background: "linear-gradient(180deg, #2F2A31 0%, rgba(47, 42, 49, 0.27) 100%)",
                    backgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontWeight: "bold",
                    mb: 3
                  }}>
                  {productQuantityUnit > 0 ? (
                    <>
                      {formatStock(productQuantityUnit)} {quantityUnit} (
                      {Math.floor(product.packagesOnStock ?? 0)} {quantityTitle})
                    </>
                  ) : (
                    noStock
                  )}
                </Typography>
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  textAlign="center"
                  sx={{ fontWeight: 300 }}>
                  {package1} - {formatStock(roundNumber(product.amountPerPackage ?? 0), 3)}{" "}
                  {packageUnit} ({palette} -{" "}
                  {formatStock(roundNumber(product.amountPerPalette ?? 0))} {packageUnit})
                </Typography>
              </>
            )}

            {isPreparing && (
              <Alert severity="warning" sx={{ width: "100%" }}>
                <RenderContent content={stockMessage?.json} />
              </Alert>
            )}
          </Grid>
        </Grid>
      </Paper>

      {!user && (
        <Alert
          severity="info"
          sx={{
            mb: 6,
            "& .MuiAlert-message": {
              width: "100%"
            }
          }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: { xs: "center", sm: "space-between" }
            }}>
            <RenderContent content={priceMessage?.json} />
            <NavButton
              sx={{
                whiteSpace: { xs: "auto", sm: "nowrap" },
                ml: { xs: 0, sm: 5 },
                mt: { xs: 5, sm: 0 }
              }}
              label={priceDiscountButton as string}
              route={routes.partner}
              endIcon={<ArrowRightAltIcon />}
              variant={ButtonVariant.secondary}
            />
          </Box>
        </Alert>
      )}

      {!user && (
        <Box sx={{ mb: 6 }}>
          <BecomePartnerBanner />
        </Box>
      )}

      <Box sx={{ mb: 6 }}>
        <SpecialProductsSelection
          title={alternativeProductsLabel as string}
          products={alternativeProducts}
        />
      </Box>

      <SpecialProductsSelection
        title={seriesProductsLabel as string}
        products={bathroomSeriesProducts}
      />
    </Box>
  );
};

export default ProductPage;
