export const colors = {
  black: "#272727",
  white: "#FFFFFF",
  grey: "#252126",
  lightGrey: "#D9D9D9",
  darkGrey: "#888888",
  green: "#7AC824",
  darkGreen: "#5d991d",
  brown: "#231F24",
  lightBrown: "#48424B",
  capuccino: "#F2ECE7",
  facebook1: "#0064E6",
  facebook2: "#1AABFE",
  instagram1: "#5E55CD",
  instagram2: "#DF3475",
  statusInfoBackground: "#D9EBFB",
  statusInfoText: "#5C84A8",
  statusSuccessBackground: "#E7FAF5",
  statusSuccessText: "#688D84",
  statusWarningBackground: "#FFF4E5",
  statusWarningText: "#9C825F",
  statusErrorBackground: "#FDE9E9",
  statusErrorText: "#9B6C6C",
  yellow: "#ffe100",
  red: "#c50000"
};

export const tileColors = {
  beige: "#F2ECE7",
  brown: "#674747",
  red: "#B31312",
  green: "#829460",
  yellow: "#F8DE22",
  blue: "#1450A3",
  white: "#FFFFFF",
  black: "#272727",
  gray: "#A8A196"
};
