import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import HomeIcon from "@mui/icons-material/Home";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { Box, Chip, Divider, Stack, SxProps, Typography } from "@mui/material";
import { useContext, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Stock, StockEditionsEnum, StockUnitEnum } from "../../api";
import { routes } from "../../constants/routes";
import {
  designOptions,
  editionColorMapping,
  editionFontMapping,
  editionOptions,
  surfaceOptions,
  technicalOptions,
  typeOptions
} from "../../constants/stock-attributes";
import { ContentfulContext } from "../../context/ContentfulContext";
import {
  DesignAttribute,
  EditionAttribute,
  Product,
  Products,
  SurfaceAttribute,
  TechnicalAttribute,
  TypeAttribute
} from "../../models/schema";
import { getButton } from "../../querries/getters";
import { colors } from "../../theme/colors";
import { formatPrice, formatStock, getImagePath, roundNumber } from "../../utils/utilities";
import { ButtonSize, ButtonVariant } from "../utils/Button";
import NavButton from "../utils/NavButton";

const ProductCard = ({ product, sx = {} }: { product: Stock; sx?: SxProps }) => {
  const { content } = useContext(ContentfulContext);
  const buttons = content?.buttonsCollection?.items;

  const { meterUnit, pieceUnit, pieceUnit2, pieceUnit3 } = content?.product as Product;
  const { preparing, noStock } = content?.products as Products;

  const typeAttributes = content?.typeAttributesCollection?.items as TypeAttribute[];
  const editionAttributes = content?.editionAttributesCollection?.items as EditionAttribute[];
  const technicalAttributes = content?.technicalAttributesCollection?.items as TechnicalAttribute[];
  const surfaceAttributes = content?.surfaceAttributesCollection?.items as SurfaceAttribute[];
  const designAttributes = content?.designAttributesCollection?.items as DesignAttribute[];

  const type = useMemo(() => typeOptions(typeAttributes), [typeAttributes]);
  const edition = useMemo(() => editionOptions(editionAttributes), [editionAttributes]);
  const technical = useMemo(() => technicalOptions(technicalAttributes), [technicalAttributes]);
  const surface = useMemo(() => surfaceOptions(surfaceAttributes), [surfaceAttributes]);
  const design = useMemo(() => designOptions(designAttributes), [designAttributes]);

  const priceToUse = product.priceForCurrentUser ?? product.price ?? 0;

  const [imageError, setImageError] = useState(false);

  const productRoute = `${routes.products}/${product.ean}`;

  const unit =
    product.unit === StockUnitEnum.Meter ? content.product?.meterUnit : content.product?.pieceUnit;

  const productQuantityUnit = roundNumber(
    (product.packagesOnStock ?? 0) * (product.amountPerPackage ?? 0)
  );

  const quantityUnit =
    product.unit === StockUnitEnum.Meter
      ? meterUnit
      : !product.packagesOnStock
      ? pieceUnit3
      : product.packagesOnStock === 1
      ? pieceUnit
      : product.packagesOnStock >= 2 && product.packagesOnStock < 5
      ? pieceUnit2
      : pieceUnit3;

  const isPreparing = product.editions?.includes(StockEditionsEnum.Preparing);

  const StockIcon = ({ sx = {} }: { sx: SxProps }) =>
    isPreparing ? (
      <ScheduleIcon sx={sx} />
    ) : productQuantityUnit > 0 ? (
      <HomeIcon sx={sx} />
    ) : (
      <DirectionsBoatIcon sx={sx} />
    );

  const stock = isPreparing
    ? preparing
    : productQuantityUnit > 0
    ? `${formatStock(productQuantityUnit)} ${quantityUnit}`
    : noStock;

  // We want to filter out the "ks" part of the name
  const productName =
    product.name
      ?.split(" ")
      .filter((part) => part !== "ks")
      .join(" ") ?? "";

  const tileImagePath = product?.tileFaceImages?.[0];

  const ProductImage = () => (
    <Box
      onError={() => setImageError(true)}
      component="img"
      src={getImagePath(tileImagePath)}
      alt={product.name}
    />
  );

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        minWidth: { xs: theme.spacing(45), md: theme.spacing(50) },
        maxWidth: { xs: theme.spacing(45), md: theme.spacing(50) },
        borderRadius: theme.spacing(4),
        background: "white",
        overflow: "hidden",
        boxShadow: "0px 0px 10px 3px rgba(0, 0, 0, 0.25)",
        m: 2,
        ...(sx as any)
      })}>
      <Link to={productRoute} style={{ textDecoration: "none", position: "relative" }}>
        {tileImagePath && !imageError ? (
          <Box
            sx={(theme) => ({
              height: { xs: theme.spacing(45), md: theme.spacing(50) },
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center"
            })}>
            <ProductImage />
          </Box>
        ) : (
          <Box
            sx={(theme) => ({
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: { xs: theme.spacing(25), md: theme.spacing(40) }
            })}>
            <ImageNotSupportedIcon
              sx={(theme) => ({
                fontSize: { xs: theme.spacing(5), md: theme.spacing(10) },
                mt: 5,
                color: colors.brown
              })}
            />
          </Box>
        )}

        <Stack
          sx={{ position: "absolute", top: 0, right: 0, p: 2 }}
          direction={{ xs: "column", md: "row" }}
          spacing={1}>
          {product.editions?.map((value) => (
            <Chip
              sx={{
                backgroundColor: editionColorMapping[value],
                color: editionFontMapping[value],
                mb: 1,
                cursor: "pointer",
                boxShadow:
                  "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
              }}
              key={value}
              label={edition.find((opt) => opt.value === value)?.label}
            />
          ))}
        </Stack>
      </Link>
      <Box
        p={3}
        sx={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
          justifyContent: "center"
        }}>
        <Typography variant="h5" textAlign="center" fontWeight="bold" mb={2}>
          {productName}
        </Typography>

        {/* <Stack sx={{ width: "100%", justifyContent: "center" }} direction="row" spacing={1} mb={4}>
          {product.thickness && (
            <Chip
              sx={{
                boxShadow:
                  "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
              }}
              label={thicknessOptionsMap[product.thickness]}
            />
          )}
          {product.colors?.map((value) => (
            <Chip
              sx={{
                backgroundColor: colorMapping[value],
                backgroundImage:
                  (value as StockColorsEnum) === StockColorsEnum.Other
                    ? "conic-gradient(red 0deg 120deg, #009f05 120deg 240deg, blue 240deg 360deg)"
                    : "none",
                color: colorFontMapping[value],
                boxShadow:
                  "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
              }}
              key={value}
              label={color.find((opt) => opt.value === value)?.label}
            />
          ))}
        </Stack> */}

        {[
          ...(product.types ?? []),
          ...(product.technicalParameters ?? []),
          ...(product.surfaces ?? []),
          ...(product.designs ?? [])
        ].length > 0 && <Divider sx={{ width: "100%", mb: 3 }} />}

        {product.types && product.types.length > 0 && (
          <Stack direction="row" alignItems="center" spacing={2} mb={2}>
            <PlayArrowOutlinedIcon />
            <Typography variant="body1" fontWeight="bold">
              {product.types.map((t) => type.find((opt) => opt.value === t)?.label).join(", ")}
            </Typography>
          </Stack>
        )}

        {product.technicalParameters && product.technicalParameters.length > 0 && (
          <Stack direction="row" spacing={2} mb={2}>
            <PlayArrowOutlinedIcon />
            <Typography variant="body1" fontWeight="bold">
              {product.technicalParameters
                .map((t) => technical.find((opt) => opt.value === t)?.label)
                .join(", ")}
            </Typography>
          </Stack>
        )}

        {product.surfaces && product.surfaces.length > 0 && (
          <Stack direction="row" spacing={2} mb={2}>
            <PlayArrowOutlinedIcon />
            <Typography variant="body1" fontWeight="bold">
              {product.surfaces
                .map((t) => surface.find((opt) => opt.value === t)?.label)
                .join(", ")}
            </Typography>
          </Stack>
        )}

        {product.designs && product.designs.length > 0 && (
          <Stack direction="row" spacing={2} mb={2}>
            <PlayArrowOutlinedIcon />
            <Typography variant="body1" fontWeight="bold">
              {product.designs.map((t) => design.find((opt) => opt.value === t)?.label).join(", ")}
            </Typography>
          </Stack>
        )}

        <Box
          sx={{
            flex: 1,
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "center"
          }}>
          <Divider sx={{ width: "100%", my: 2 }} />

          <Typography
            fontWeight="bold"
            textAlign="center"
            variant="h4"
            sx={{
              background: "linear-gradient(180deg, #2F2A31 0%, rgba(47, 42, 49, 0.27) 100%)",
              backgroundClip: "text",
              WebkitTextFillColor: "transparent",
              fontWeight: "bold",
              mb: 2
            }}>
            {formatPrice(priceToUse)} / {unit}
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <StockIcon sx={{ mr: 2 }} />
            <Typography
              variant="body2"
              fontWeight="bold"
              textAlign="center"
              sx={{ fontWeight: 300 }}>
              {stock}
            </Typography>
          </Box>
        </Box>

        <NavButton
          route={productRoute}
          label={getButton(buttons, "detail")}
          variant={ButtonVariant.primary}
          size={ButtonSize.full}
          sx={{ mt: 3 }}
        />
      </Box>
    </Box>
  );
};

export default ProductCard;
