import { SxProps } from "@mui/material";
import { FC } from "react";
import { Link } from "react-router-dom";
import Button, { ButtonProps } from "./Button";

type NavButtonProps = {
  route: string;
  isActive?: boolean;
  activeStyle?: SxProps;
  external?: boolean;
} & ButtonProps;

const NavButton: FC<NavButtonProps> = ({
  route,
  activeStyle,
  isActive,
  external = false,
  sx,
  ...props
}) => {
  let style: any = {};

  if (sx) {
    style = {
      ...style,
      ...sx
    };
  }

  if (isActive && activeStyle) {
    style = {
      ...style,
      ...activeStyle
    };
  }

  return external ? (
    <a href={route} style={{ textDecoration: "none" }}>
      <Button sx={style} {...props}></Button>
    </a>
  ) : (
    <Link to={route} style={{ textDecoration: "none" }}>
      <Button sx={style} {...props}></Button>
    </Link>
  );
};

export default NavButton;
