import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { Box, colors } from "@mui/material";

const CheckboxIcon = ({ checked }: { checked?: boolean }) => (
  <Box
    sx={(theme) => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: "3px solid",
      borderRadius: 3,
      p: 1,
      borderColor: colors.brown,
      "& svg": {
        width: theme.spacing(4),
        height: theme.spacing(4)
      }
    })}>
    {checked ? <CheckOutlinedIcon /> : <svg></svg>}
  </Box>
);

export default CheckboxIcon;
