import { Stack, SxProps, Typography } from "@mui/material";
import { ReactNode } from "react";
import Button, { ButtonVariant } from "../utils/Button";
import NavButton from "../utils/NavButton";

type TileAttributeProps = {
  title: string;
  icon: ReactNode;
  route?: string;
  sx?: SxProps;
};

const TileAttribute = ({ title, icon, route, sx = {} }: TileAttributeProps) => {
  return (
    <Stack direction="row" spacing={4} sx={{ display: "flex", alignItems: "center", pr: 5, ...sx }}>
      {route ? (
        <NavButton route={route} startIcon={icon} variant={ButtonVariant.secondary} />
      ) : (
        <Button startIcon={icon} variant={ButtonVariant.secondary} />
      )}
      <Typography variant="body2">{title}</Typography>
    </Stack>
  );
};

export default TileAttribute;
