import {
  StockColorsEnum,
  StockDesignsEnum,
  StockEditionsEnum,
  StockSizeEnum,
  StockSurfacesEnum,
  StockTechnicalParametersEnum,
  StockThicknessEnum,
  StockTypesEnum
} from "../api";
import {
  ColorAttribute,
  DesignAttribute,
  EditionAttribute,
  SurfaceAttribute,
  TechnicalAttribute,
  TypeAttribute
} from "../models/schema";
import { getTileAttribute } from "../querries/getters";
import { colors, tileColors } from "../theme/colors";

export const StockFilters = {
  Type: "type",
  Discount: "discount",
  Discontinued: "discontinued",
  Preparing: "preparing",
  New: "new",
  Size: "size",
  Thickness: "thickness",
  Design: "design",
  Surface: "surface",
  Technical: "technical",
  Color: "color",
  Name: "name",
  Price: "price",
  Quantity: "quantity"
};
export type StockFilters = (typeof StockFilters)[keyof typeof StockFilters];

export type SelectOption = {
  label: string;
  value: string;
};

export const sizeOptionsMap: { [key in StockSizeEnum]: string } = {
  [StockSizeEnum._7530]: "7,5x30",
  [StockSizeEnum._1590]: "15x90",
  [StockSizeEnum._2175]: "21x7,5",
  [StockSizeEnum._25575]: "25,5x7,5",
  [StockSizeEnum._3030]: "30x30",
  [StockSizeEnum._3060]: "30x60",
  [StockSizeEnum._39898]: "39,8x9,8",
  [StockSizeEnum._408283]: "40,8x28,3",
  [StockSizeEnum._4040]: "40x40",
  [StockSizeEnum._4545]: "45x45",
  [StockSizeEnum._492145]: "49,2x14,5",
  [StockSizeEnum._5050]: "50x50",
  [StockSizeEnum._6060]: "60x60",
  [StockSizeEnum._60120]: "60x120",
  [StockSizeEnum._8080]: "80x80",
  [StockSizeEnum._120120]: "120x120",
  [StockSizeEnum._80160]: "80x160",
  [StockSizeEnum._100200]: "100x200"
};

export const thicknessOptionsMap: { [key in StockThicknessEnum]: string } = {
  [StockThicknessEnum._5Mm]: "5 mm",
  [StockThicknessEnum._7Mm]: "7 mm",
  [StockThicknessEnum._75Mm]: "7,5 mm",
  [StockThicknessEnum._8Mm]: "8 mm",
  [StockThicknessEnum._85Mm]: "8,5 mm",
  [StockThicknessEnum._9Mm]: "9 mm",
  [StockThicknessEnum._10Mm]: "10 mm",
  [StockThicknessEnum._2Cm]: "20 mm",
  [StockThicknessEnum._30Mm]: "30 mm",
  [StockThicknessEnum._35Mm]: "35 mm"
};

export const colorMapping: { [key in StockColorsEnum]: string | undefined } = {
  [StockColorsEnum.Beige]: tileColors.beige,
  [StockColorsEnum.Brown]: tileColors.brown,
  [StockColorsEnum.Red]: tileColors.red,
  [StockColorsEnum.Green]: tileColors.green,
  [StockColorsEnum.Yellow]: tileColors.yellow,
  [StockColorsEnum.Blue]: tileColors.blue,
  [StockColorsEnum.White]: tileColors.white,
  [StockColorsEnum.Black]: tileColors.black,
  [StockColorsEnum.Gray]: tileColors.gray,
  [StockColorsEnum.Other]: undefined
};

export const colorFontMapping: {
  [key in StockColorsEnum]: string | undefined;
} = {
  [StockColorsEnum.Beige]: "black",
  [StockColorsEnum.Brown]: "white",
  [StockColorsEnum.Red]: "white",
  [StockColorsEnum.Green]: "black",
  [StockColorsEnum.Yellow]: "black",
  [StockColorsEnum.Blue]: "white",
  [StockColorsEnum.White]: "black",
  [StockColorsEnum.Black]: "white",
  [StockColorsEnum.Gray]: "black",
  [StockColorsEnum.Other]: "white"
};

export const editionColorMapping: { [key in StockEditionsEnum]: string } = {
  [StockEditionsEnum.New]: colors.green,
  [StockEditionsEnum.Preparing]: colors.capuccino,
  // [StockEditionsEnum.Recommended]: colors.yellow,
  [StockEditionsEnum.Discount]: colors.red,
  [StockEditionsEnum.Discontinued]: colors.yellow
};

export const editionFontMapping: { [key in StockEditionsEnum]: string } = {
  [StockEditionsEnum.New]: "white",
  [StockEditionsEnum.Preparing]: "black",
  // [StockEditionsEnum.Recommended]: "black",
  [StockEditionsEnum.Discount]: "white",
  [StockEditionsEnum.Discontinued]: "black"
};

export const sizeOptions = Object.entries(sizeOptionsMap).map(([key, value]) => ({
  label: value,
  value: key
}));

export const thicknessOptions = Object.entries(thicknessOptionsMap).map(([key, value]) => ({
  label: value,
  value: key
}));

export const typeOptions = (typeOptions: TypeAttribute[]): SelectOption[] =>
  Object.values(StockTypesEnum).map((value) => ({
    label: getTileAttribute(typeOptions, value),
    value: value
  }));

export const editionOptions = (editionOptions: EditionAttribute[]): SelectOption[] =>
  Object.values(StockEditionsEnum).map((value) => ({
    label: getTileAttribute(editionOptions, value),
    value: value
  }));

export const technicalOptions = (technicalOptions: TechnicalAttribute[]): SelectOption[] =>
  Object.values(StockTechnicalParametersEnum).map((value) => ({
    label: getTileAttribute(technicalOptions, value),
    value: value
  }));

export const surfaceOptions = (surfaceOptions: SurfaceAttribute[]): SelectOption[] =>
  Object.values(StockSurfacesEnum).map((value) => ({
    label: getTileAttribute(surfaceOptions, value),
    value: value
  }));

export const designOptions = (designOptions: DesignAttribute[]): SelectOption[] =>
  Object.values(StockDesignsEnum).map((value) => ({
    label: getTileAttribute(designOptions, value),
    value: value
  }));

export const colorOptions = (colorOptions: ColorAttribute[]): SelectOption[] =>
  Object.values(StockColorsEnum).map((value) => ({
    label: getTileAttribute(colorOptions, value),
    value: value
  }));

export const priorityAttributes: Array<
  | StockTypesEnum
  | StockEditionsEnum
  | StockTechnicalParametersEnum
  | StockSurfacesEnum
  | StockDesignsEnum
  | StockColorsEnum
> = [
  StockTypesEnum.Flooring,
  StockTypesEnum.Tiling,
  StockTechnicalParametersEnum.FrostResistant,
  StockTechnicalParametersEnum.NonFrostResistant,
  StockSurfacesEnum.Matte,
  StockSurfacesEnum.Gloss,
  StockSurfacesEnum.HighGloss,
  StockTechnicalParametersEnum.ResistanceToSurfaceWearPei1,
  StockTechnicalParametersEnum.ResistanceToSurfaceWearPei2,
  StockTechnicalParametersEnum.ResistanceToSurfaceWearPei3,
  StockTechnicalParametersEnum.ResistanceToSurfaceWearPei4,
  StockTechnicalParametersEnum.AntiSlipR9,
  StockTechnicalParametersEnum.AntiSlipR10,
  StockTechnicalParametersEnum.AntiSlipR11
];

export const sortAttributeFunction = (
  a:
    | StockTypesEnum
    | StockEditionsEnum
    | StockTechnicalParametersEnum
    | StockSurfacesEnum
    | StockDesignsEnum
    | StockColorsEnum,
  b:
    | StockTypesEnum
    | StockEditionsEnum
    | StockTechnicalParametersEnum
    | StockSurfacesEnum
    | StockDesignsEnum
    | StockColorsEnum
) => {
  const indexOfA = priorityAttributes.indexOf(a);
  const indexOfB = priorityAttributes.indexOf(b);

  if (indexOfA > indexOfB) return 1;
  if (indexOfA < indexOfB) return -1;

  return 0;
};

export const getAttributeType = (
  attribute:
    | StockTypesEnum
    | StockEditionsEnum
    | StockTechnicalParametersEnum
    | StockSurfacesEnum
    | StockDesignsEnum
    | StockColorsEnum
) => {
  if (Object.values(StockTypesEnum).includes(attribute as StockTypesEnum)) {
    return StockFilters.Type;
  }

  if (attribute === StockEditionsEnum.Discount) {
    return StockFilters.Discount;
  }

  if (attribute === StockEditionsEnum.Preparing) {
    return StockFilters.Preparing;
  }

  if (attribute === StockEditionsEnum.New) {
    return StockFilters.New;
  }

  if (attribute === StockEditionsEnum.Discontinued) {
    return StockFilters.Discontinued;
  }

  if (
    Object.values(StockTechnicalParametersEnum).includes(attribute as StockTechnicalParametersEnum)
  ) {
    return StockFilters.Technical;
  }

  if (Object.values(StockSurfacesEnum).includes(attribute as StockSurfacesEnum)) {
    return StockFilters.Surface;
  }

  if (Object.values(StockDesignsEnum).includes(attribute as StockDesignsEnum)) {
    return StockFilters.Design;
  }

  if (Object.values(StockColorsEnum).includes(attribute as StockColorsEnum)) {
    return StockFilters.Color;
  }

  return;
};
