import { Box, CircularProgress } from "@mui/material";
import { colors } from "../../theme/colors";

const LoadingSpinner = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        my: 10
      }}>
      <CircularProgress
        sx={{
          color: colors.brown
        }}
      />
    </Box>
  );
};

export default LoadingSpinner;
