import { MutableRefObject, useContext } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { LanguageContext } from "../../context/LanguageContext";
import { RecaptchaContext } from "../../context/RecaptchaContext";

type GoogleRecaptchaProps = {
  recaptchaRef: MutableRefObject<ReCAPTCHA | null>;
};

const GoogleRecaptcha = ({ recaptchaRef }: GoogleRecaptchaProps) => {
  const language = useContext(LanguageContext);
  const { badgeVisible, neccessaryCookiesAllowed } = useContext(RecaptchaContext);
  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  return (
    // Only load recaptcha if the user has accepted the necessary cookies
    neccessaryCookiesAllowed ? (
      <ReCAPTCHA
        style={{
          zIndex: 1000,
          visibility: badgeVisible ? "visible" : "hidden",
          position: "relative"
        }}
        ref={recaptchaRef}
        size="invisible"
        hl={language.selectedLanguage} // https://developers.google.com/recaptcha/docs/language
        badge="bottomleft"
        sitekey={siteKey ?? ""}
        onErrored={() => console.log("Recaptcha error")}
        onExpired={() => console.log("Recaptcha expired")}
      />
    ) : (
      <></>
    )
  );
};

export default GoogleRecaptcha;
