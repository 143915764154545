import {
  AuthControllerApi,
  Configuration,
  ContactControllerApi,
  OrderControllerApi,
  StockControllerApi
} from "../api";

const basePath = process.env.REACT_APP_API_BASE_PATH;

const config = new Configuration({
  basePath
});

export const authApi = new AuthControllerApi(config);
export const orderApi = new OrderControllerApi(config);
export const contactApi = new ContactControllerApi(config);
export const stockApi = new StockControllerApi(config);
