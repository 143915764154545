import { AppBar, Box, Container, Drawer, IconButton, Typography } from "@mui/material";

import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { routes } from "../../constants/routes";
import { CartContext } from "../../context/CartContext";
import { ContentfulContext } from "../../context/ContentfulContext";
import { Header as HeaderModel } from "../../models/schema";
import { colors } from "../../theme/colors";
import { AccountAvatar } from "../utils/AccountAvatar";
import Button, { ButtonVariant } from "../utils/Button";
import CartButton from "../utils/CartButton";
import { LanguageSelector } from "../utils/LanguageSelector";
import NavigationPanel from "../utils/NavigationPanel";
import Logo from "./Logo";

const Header = () => {
  const [mobileNavOpen, setMobileNavOpen] = useState<boolean>(false);

  const { cart } = useContext(CartContext);
  const { content } = useContext(ContentfulContext);
  const { account, navigation, cart: cartLabel } = content?.header as HeaderModel;

  return (
    <AppBar
      position="fixed"
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        backgroundColor: "transparent",
        boxShadow: "none",
        backgroundImage: "none"
      }}>
      <Container
        maxWidth="xl"
        sx={{
          width: "100%",
          mx: { xs: 2, lg: 4 },
          p: { xs: 3, lg: 5 },
          color: "white",
          background: `linear-gradient(84deg, ${colors.brown} 0%, ${colors.lightBrown} 100%)`,
          filter: "drop-shadow(-4px 5px 4px rgba(0, 0, 0, 0.25))",
          borderBottomLeftRadius: { xs: 50, lg: 80 },
          borderBottomRightRadius: { xs: 15, lg: 30 }
        }}>
        {/* Mobile */}
        <Box
          display={{ xs: "flex", lg: "none" }}
          justifyContent="space-between"
          alignItems="center">
          <Link
            to={routes.home}
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "70%"
            }}>
            <Logo variant="header" />
          </Link>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ ml: "auto" }}
            onClick={() => setMobileNavOpen(true)}>
            <MenuIcon />

            {cart?.length > 0 && (
              <Box
                sx={(theme) => ({
                  position: "absolute",
                  borderRadius: "100%",
                  width: theme.spacing(5),
                  height: theme.spacing(5),
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "0.8rem",
                  fontWeight: "900",
                  top: theme.spacing(-0.5),
                  left: theme.spacing(-0.5),
                  backgroundColor: "red",
                  color: "white"
                })}>
                {cart.length}
              </Box>
            )}
          </IconButton>

          <Drawer
            anchor="right"
            open={mobileNavOpen}
            onClose={() => setMobileNavOpen(false)}
            sx={{
              "& .MuiPaper-root, .MuiBackdrop-root": {
                width: "80%",
                borderTopRightRadius: "0",
                borderBottomRightRadius: "0"
              }
            }}>
            <Box
              sx={{
                p: 5,
                minHeight: 1,
                display: "flex",
                flexDirection: "column"
              }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mb: 1,
                  "& .": {
                    width: "100%"
                  },
                  "& > :not(template) ~ :not(template)": {
                    mt: 5
                  }
                }}>
                <Box>
                  <Typography variant="h5" mb={3}>
                    {navigation}
                  </Typography>
                  <NavigationPanel mobile setMobileNavOpen={() => setMobileNavOpen(false)} />
                </Box>

                <Box>
                  <Typography variant="h5" mb={3}>
                    {cartLabel}
                  </Typography>
                  <CartButton mobile setMobileNavOpen={() => setMobileNavOpen(false)} />
                </Box>

                <LanguageSelector mobile />

                <Box>
                  <Typography variant="h5" mb={3}>
                    {account}
                  </Typography>
                  <AccountAvatar mobile setMobileNavOpen={() => setMobileNavOpen(false)} />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    flexGrow: 1,
                    justifyContent: "flex-end",
                    pb: 5
                  }}>
                  <Button
                    variant={ButtonVariant.secondary}
                    onClick={() => setMobileNavOpen(false)}
                    startIcon={<CloseOutlinedIcon />}
                  />
                </Box>
              </Box>
            </Box>
          </Drawer>
        </Box>

        {/* Desktop */}
        <Box
          sx={{
            display: { xs: "none", lg: "flex" },
            alignItems: "center"
          }}>
          <Link
            to={routes.home}
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center"
            }}>
            <Logo variant="header" />
          </Link>

          <Box
            sx={{
              display: { xs: "none", lg: "flex" },
              alignItems: "center",
              ml: "auto",
              whiteSpace: "nowrap"
            }}>
            <NavigationPanel />
          </Box>
          <Box
            sx={{
              display: { xs: "none", lg: "flex" },
              alignItems: "center",
              ml: 14,
              whiteSpace: "nowrap",
              "& > :not(template) ~ :not(template)": {
                ml: 2
              }
            }}>
            {/* <SwitchModeButton /> */}
            <CartButton />
            <LanguageSelector />
            <AccountAvatar />
          </Box>
        </Box>
      </Container>
    </AppBar>
  );
};

export default Header;
