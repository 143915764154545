import { Box, Typography } from "@mui/material";
import { useContext } from "react";
import { ReactImageGalleryItem } from "react-image-gallery";
import { Anchors } from "../../constants/routes";
import { ContentfulContext } from "../../context/ContentfulContext";
import { Showroom as ShowroomModel } from "../../models/schema";
import ImagePreview from "../utils/ImagePreview";
import RenderContent from "../utils/RenderContent";

const Showroom = () => {
  const { content } = useContext(ContentfulContext);
  const { title, description, photosCollection } = content?.home?.showroom as ShowroomModel;

  const images: Array<ReactImageGalleryItem> =
    photosCollection?.items?.map((item) => ({
      original: item?.url as string
    })) ?? [];

  return (
    <Box
      id={Anchors.showroom}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}>
      <Typography variant="h4" fontWeight="bold" mb={{ xs: 6, md: 8 }}>
        {title}
      </Typography>
      <RenderContent content={description?.json} sx={{ mb: 8 }} />
      <ImagePreview images={images} autoplay showPlayButton />
    </Box>
  );
};

export default Showroom;
