/* tslint:disable */
/* eslint-disable */
/**
 * EcoCerram API
 * EcoCerram API generated from BE
 *
 * The version of the OpenAPI document: 0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from "../runtime";
/**
 *
 * @export
 * @interface OrderItemDto
 */
export interface OrderItemDto {
  /**
   *
   * @type {string}
   * @memberof OrderItemDto
   */
  ean?: string;
  /**
   *
   * @type {number}
   * @memberof OrderItemDto
   */
  quantity?: number;
}

/**
 * Check if a given object implements the OrderItemDto interface.
 */
export function instanceOfOrderItemDto(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function OrderItemDtoFromJSON(json: any): OrderItemDto {
  return OrderItemDtoFromJSONTyped(json, false);
}

export function OrderItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderItemDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ean: !exists(json, "ean") ? undefined : json["ean"],
    quantity: !exists(json, "quantity") ? undefined : json["quantity"]
  };
}

export function OrderItemDtoToJSON(value?: OrderItemDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ean: value.ean,
    quantity: value.quantity
  };
}
