import React from "react";
import { Stock } from "../api";

export interface ProductsContextSchema {
  loading: boolean;
  products: Stock[];
  reloadProducts: () => void;
}

export const ProductsContext = React.createContext<ProductsContextSchema>(
  {} as ProductsContextSchema
);
