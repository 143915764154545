import AcUnitOutlinedIcon from "@mui/icons-material/AcUnitOutlined";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import BackHandIcon from "@mui/icons-material/BackHand";
import BlurOffIcon from "@mui/icons-material/BlurOff";
import BlurOnIcon from "@mui/icons-material/BlurOn";
import BorderStyleIcon from "@mui/icons-material/BorderStyle";
import HouseSharpIcon from "@mui/icons-material/HouseSharp";
import SevereColdIcon from "@mui/icons-material/SevereCold";
import ShowerSharpIcon from "@mui/icons-material/ShowerSharp";
import { Box, SxProps } from "@mui/material";
import { ReactNode, useContext } from "react";
import {
  StockColorsEnum,
  StockDesignsEnum,
  StockSurfacesEnum,
  StockTechnicalParametersEnum,
  StockTypesEnum
} from "../../api";
import { routes } from "../../constants/routes";
import { StockFilters } from "../../constants/stock-attributes";
import { ContentfulContext } from "../../context/ContentfulContext";
import threeDIcon from "../../icons/3d.png";
import abstractIcon from "../../icons/abstract.png";
import concreteIcon from "../../icons/concrete.png";
import decorationIcon from "../../icons/decoration.png";
import mosaicHexagonIcon from "../../icons/hexagon.png";
import marbleIcon from "../../icons/marble.png";
import metalIcon from "../../icons/metal.png";
import pei1Icon from "../../icons/pei1.png";
import pei2Icon from "../../icons/pei2.png";
import pei3Icon from "../../icons/pei3.png";
import pei4Icon from "../../icons/pei4.png";
import r10Icon from "../../icons/r10.png";
import r11Icon from "../../icons/r11.png";
import r9Icon from "../../icons/r9.png";
import stepsIcon from "../../icons/steps.png";
import stoneIcon from "../../icons/stone.png";
import universalIcon from "../../icons/universal.png";
import veinsIcon from "../../icons/veins.png";
import woodIcon from "../../icons/wood.png";
import {
  ColorAttribute,
  DesignAttribute,
  SurfaceAttribute,
  TechnicalAttribute,
  TypeAttribute
} from "../../models/schema";
import { getTileAttribute } from "../../querries/getters";
import { tileColors } from "../../theme/colors";
import TileAttribute from "./TileAttribute";

type AtributeContent = {
  title: string;
  icon?: ReactNode;
  color?: string;
};

type TileAttributesProps = {
  name:
    | StockTypesEnum
    | StockDesignsEnum
    | StockTechnicalParametersEnum
    | StockSurfacesEnum
    | StockColorsEnum[];
  type?: StockFilters;
  sx?: SxProps;
};

const TileAttributes = ({ name, type, sx }: TileAttributesProps) => {
  const { content } = useContext(ContentfulContext);
  const typeAttributes = content?.typeAttributesCollection?.items as TypeAttribute[];
  const technicalAttributes = content?.technicalAttributesCollection?.items as TechnicalAttribute[];
  const surfaceAttributes = content?.surfaceAttributesCollection?.items as SurfaceAttribute[];
  const designAttributes = content?.designAttributesCollection?.items as DesignAttribute[];
  const colorAttributes = content?.colorAttributesCollection?.items as ColorAttribute[];

  if (!type) {
    return <>TYPE NOT PROVIDED</>;
  }

  const attributesDefinition: {
    [type in StockFilters]: { [name: string]: AtributeContent };
  } = {
    type: {
      [StockTypesEnum.Tiling]: {
        title: getTileAttribute(typeAttributes, StockTypesEnum.Tiling),
        icon: <BackHandIcon />
      },
      [StockTypesEnum.Flooring]: {
        title: getTileAttribute(typeAttributes, StockTypesEnum.Flooring),
        icon: <Box component={"img"} src={stepsIcon} />
      },
      [StockTypesEnum.BathroomSeries]: {
        title: getTileAttribute(typeAttributes, StockTypesEnum.BathroomSeries),
        icon: <ShowerSharpIcon />
      },
      [StockTypesEnum.Decoration]: {
        title: getTileAttribute(typeAttributes, StockTypesEnum.Decoration),
        icon: <Box component={"img"} src={decorationIcon} />
      },
      [StockTypesEnum.FacadeCladding]: {
        title: getTileAttribute(typeAttributes, StockTypesEnum.FacadeCladding),
        icon: <HouseSharpIcon />
      },
      [StockTypesEnum.MosaicHexagon]: {
        title: getTileAttribute(typeAttributes, StockTypesEnum.MosaicHexagon),
        icon: <Box component={"img"} src={mosaicHexagonIcon} />
      }
    },
    technical: {
      [StockTechnicalParametersEnum.FrostResistant]: {
        title: getTileAttribute(technicalAttributes, StockTechnicalParametersEnum.FrostResistant),
        icon: <AcUnitOutlinedIcon />
      },
      [StockTechnicalParametersEnum.NonFrostResistant]: {
        title: getTileAttribute(
          technicalAttributes,
          StockTechnicalParametersEnum.NonFrostResistant
        ),
        icon: <SevereColdIcon color="error" />
      },
      [StockTechnicalParametersEnum.AntiSlipR9]: {
        title: getTileAttribute(technicalAttributes, StockTechnicalParametersEnum.AntiSlipR9),
        icon: <Box component={"img"} src={r9Icon} />
      },
      [StockTechnicalParametersEnum.AntiSlipR10]: {
        title: getTileAttribute(technicalAttributes, StockTechnicalParametersEnum.AntiSlipR10),
        icon: <Box component={"img"} src={r10Icon} />
      },
      [StockTechnicalParametersEnum.AntiSlipR11]: {
        title: getTileAttribute(technicalAttributes, StockTechnicalParametersEnum.AntiSlipR11),
        icon: <Box component={"img"} src={r11Icon} />
      },
      [StockTechnicalParametersEnum.ResistanceToSurfaceWearPei1]: {
        title: getTileAttribute(
          technicalAttributes,
          StockTechnicalParametersEnum.ResistanceToSurfaceWearPei1
        ),
        icon: <Box component={"img"} src={pei1Icon} />
      },
      [StockTechnicalParametersEnum.ResistanceToSurfaceWearPei2]: {
        title: getTileAttribute(
          technicalAttributes,
          StockTechnicalParametersEnum.ResistanceToSurfaceWearPei2
        ),
        icon: <Box component={"img"} src={pei2Icon} />
      },
      [StockTechnicalParametersEnum.ResistanceToSurfaceWearPei3]: {
        title: getTileAttribute(
          technicalAttributes,
          StockTechnicalParametersEnum.ResistanceToSurfaceWearPei3
        ),
        icon: <Box component={"img"} src={pei3Icon} />
      },
      [StockTechnicalParametersEnum.ResistanceToSurfaceWearPei4]: {
        title: getTileAttribute(
          technicalAttributes,
          StockTechnicalParametersEnum.ResistanceToSurfaceWearPei4
        ),
        icon: <Box component={"img"} src={pei4Icon} />
      },
      [StockTechnicalParametersEnum.Rectified]: {
        title: getTileAttribute(technicalAttributes, StockTechnicalParametersEnum.Rectified),
        icon: <BorderStyleIcon sx={{ transform: "rotate(90deg)" }} />
      }
    },
    surface: {
      [StockSurfacesEnum.Matte]: {
        title: getTileAttribute(surfaceAttributes, StockSurfacesEnum.Matte),
        icon: <BlurOffIcon />
      },
      [StockSurfacesEnum.Gloss]: {
        title: getTileAttribute(surfaceAttributes, StockSurfacesEnum.Gloss),
        icon: <BlurOnIcon />
      },
      [StockSurfacesEnum.HighGloss]: {
        title: getTileAttribute(surfaceAttributes, StockSurfacesEnum.HighGloss),
        icon: <AutoAwesomeIcon />
      },
      [StockSurfacesEnum.Carving]: {
        title: getTileAttribute(surfaceAttributes, StockSurfacesEnum.Carving),
        icon: <Box component={"img"} src={veinsIcon} />
      },
      [StockSurfacesEnum.Texture]: {
        title: getTileAttribute(surfaceAttributes, StockSurfacesEnum.Texture),
        icon: <Box component={"img"} src={threeDIcon} />
      }
    },
    design: {
      [StockDesignsEnum.Wood]: {
        title: getTileAttribute(designAttributes, StockDesignsEnum.Wood),
        icon: <Box component={"img"} src={woodIcon} />
      },
      [StockDesignsEnum.Stone]: {
        title: getTileAttribute(designAttributes, StockDesignsEnum.Stone),
        icon: <Box component={"img"} src={stoneIcon} />
      },
      [StockDesignsEnum.Concrete]: {
        title: getTileAttribute(designAttributes, StockDesignsEnum.Concrete),
        icon: <Box component={"img"} src={concreteIcon} />
      },
      [StockDesignsEnum.Abstract]: {
        title: getTileAttribute(designAttributes, StockDesignsEnum.Abstract),
        icon: <Box component={"img"} src={abstractIcon} />
      },
      [StockDesignsEnum.Universal]: {
        title: getTileAttribute(designAttributes, StockDesignsEnum.Universal),
        icon: <Box component={"img"} src={universalIcon} />
      },
      [StockDesignsEnum.Marble]: {
        title: getTileAttribute(designAttributes, StockDesignsEnum.Marble),
        icon: <Box component={"img"} src={marbleIcon} />
      },
      [StockDesignsEnum.Metallic]: {
        title: getTileAttribute(designAttributes, StockDesignsEnum.Metallic),
        icon: <Box component={"img"} src={metalIcon} />
      }
    },
    color: {
      [StockColorsEnum.Beige]: {
        title: getTileAttribute(colorAttributes, StockColorsEnum.Beige),
        color: tileColors.beige
      },
      [StockColorsEnum.Brown]: {
        title: getTileAttribute(colorAttributes, StockColorsEnum.Brown),
        color: tileColors.brown
      },
      [StockColorsEnum.Red]: {
        title: getTileAttribute(colorAttributes, StockColorsEnum.Red),
        color: tileColors.red
      },
      [StockColorsEnum.Green]: {
        title: getTileAttribute(colorAttributes, StockColorsEnum.Green),
        color: tileColors.green
      },
      [StockColorsEnum.Yellow]: {
        title: getTileAttribute(colorAttributes, StockColorsEnum.Yellow),
        color: tileColors.yellow
      },
      [StockColorsEnum.Blue]: {
        title: getTileAttribute(colorAttributes, StockColorsEnum.Blue),
        color: tileColors.blue
      },
      [StockColorsEnum.White]: {
        title: getTileAttribute(colorAttributes, StockColorsEnum.White),
        color: tileColors.white
      },
      [StockColorsEnum.Black]: {
        title: getTileAttribute(colorAttributes, StockColorsEnum.Black),
        color: tileColors.black
      },
      [StockColorsEnum.Gray]: {
        title: getTileAttribute(colorAttributes, StockColorsEnum.Gray),
        color: tileColors.gray
      },
      [StockColorsEnum.Other]: {
        title: getTileAttribute(colorAttributes, StockColorsEnum.Other)
      }
    }
  };

  let title, icon;

  if (type === "color") {
    const attributes = (name as string[])
      .map((n) => attributesDefinition[type][n])
      .filter((attr) => attr);

    if (attributes.length === 0) return null;

    title = attributes.map((attr) => attr.title).join(", ");

    const degrees = 360 / attributes.length;
    const backgroundImage = name.includes(StockColorsEnum.Other)
      ? "conic-gradient(red 0deg 120deg, #009f05 120deg 240deg, blue 240deg 360deg)"
      : `conic-gradient(${attributes
          .reverse()
          .map((attr, index) => `${attr.color} ${index * degrees}deg ${(index + 1) * degrees}deg`)
          .join(", ")})`;
    icon = (
      <Box
        sx={{
          width: "25px",
          height: "25px",
          borderRadius: "50%",
          border: "2px solid #000",
          backgroundImage
        }}></Box>
    );
  } else {
    const attribute = attributesDefinition[type][name as string];

    if (!attribute) return null;

    title = attribute.title;
    icon = attribute.icon;
  }

  const route =
    type === "color"
      ? `${routes.products}?${type}=${(name as string[]).join(",")}`
      : `${routes.products}?${type}=${name}`;

  return <TileAttribute sx={sx} title={title} icon={icon} route={route} />;
};

export default TileAttributes;
