import { Paper, Typography } from "@mui/material";
import { Stock } from "../../api";
import { Anchors } from "../../constants/routes";
import LoadingSpinner from "../utils/LoadingSpinner";
import ProductCard from "./ProductCard";
import SelectionList from "./SelectionList";

type SpecialProductsSelectionProps = {
  title: string;
  products: Stock[];
  loading?: boolean;
};

const CARD_SIZE = 280;

const SpecialProductsSelection = ({ title, products, loading }: SpecialProductsSelectionProps) => {
  return loading || products?.length > 0 ? (
    <Paper
      id={Anchors.news}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: { xs: 2, md: 5 }
      }}>
      <Typography variant="h4" fontWeight="bold" mb={{ xs: 6, md: 8 }}>
        {title}
      </Typography>

      {loading && <LoadingSpinner />}

      {!loading && products?.length > 0 && (
        <SelectionList
          minLengthForControls={5}
          itemsLength={products?.length}
          scrollSize={CARD_SIZE}>
          {products.map((product, index) => (
            <ProductCard key={index} product={product} sx={{ my: 5, mx: 3 }} />
          ))}
        </SelectionList>
      )}
    </Paper>
  ) : (
    <></>
  );
};

export default SpecialProductsSelection;
