import React from "react";
import { OrderItemDto } from "../api";

export interface CartContextSchema {
  cart: OrderItemDto[];
  addItem: (item: OrderItemDto) => void;
  updateItem: (item: OrderItemDto) => void;
  removeItem: (ean: string) => void;
  resetCart: () => void;
}

export const CartContext = React.createContext<CartContextSchema>({} as CartContextSchema);
