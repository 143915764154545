import { Box, SxProps } from "@mui/material";
import { FC, PropsWithChildren } from "react";

type ButtonContainerProps = {
  sx?: SxProps;
};

const ButtonContainer: FC<PropsWithChildren<ButtonContainerProps>> = ({ children, sx = {} }) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: { xs: "center", md: "flex-start" },
      alignItems: { xs: "initial", md: "flex-end" },
      flexDirection: { xs: "column", md: "row" },
      whiteSpace: "nowrap",
      "& > :not(template) ~ :not(template)": {
        mt: { xs: 3, md: 0 },
        ml: { xs: 0, md: 3 }
      },
      ...sx
    }}>
    {children}
  </Box>
);

export default ButtonContainer;
