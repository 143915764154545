import { Box, Grid } from "@mui/material";
import { useContext } from "react";
import { Anchors } from "../../constants/routes";
import { ContentfulContext } from "../../context/ContentfulContext";
import { Intro as IntroModel } from "../../models/schema";
import { getButton } from "../../querries/getters";
import { smoothScroll } from "../../utils/utilities";
import Button, { ButtonVariant } from "../utils/Button";
import RenderContent from "../utils/RenderContent";

const Intro = () => {
  const { content } = useContext(ContentfulContext);

  const buttons = content?.buttonsCollection?.items;
  const { title, subtitle, description, imagesCollection } = content?.home?.intro as IntroModel;

  return (
    <Grid
      container
      spacing={8}
      sx={{
        p: { xs: 5, md: 7 }
      }}>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center"
        }}>
        <RenderContent
          content={title?.json}
          sx={{
            mb: 1
          }}
        />
        <RenderContent
          content={subtitle?.json}
          sx={{
            mb: 5,
            fontStyle: "italic"
          }}
        />
        <RenderContent
          content={description?.json}
          sx={{
            maxWidth: { xs: "100%", md: "60%" },
            mb: 5
          }}
        />

        <Box sx={{ flexGrow: 1, display: "flex", alignItems: "flex-end" }}>
          <Button
            variant={ButtonVariant.secondary}
            label={getButton(buttons, "aboutUs")}
            onClick={() => smoothScroll(Anchors.aboutUs)}
          />
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={(theme) => ({
          position: "relative",
          height: { xs: theme.spacing(60), md: theme.spacing(80) },
          "&:hover > img": {
            transform: `scale(1.2)`
          },
          "&:hover > #tile-0": {
            transform: `rotate(-15deg) scale(1.2)`
          },
          "&:hover > #tile-2": {
            transform: `rotate(15deg) scale(1.2)`
          }
        })}>
        {imagesCollection?.items?.length === 3 &&
          imagesCollection?.items?.map((image, index) => (
            <Box
              id={`tile-${index}`}
              key={index}
              component="img"
              src={image?.url as string}
              alt={image?.title as string}
              sx={(theme) => ({
                height: { xs: theme.spacing(40), md: theme.spacing(50) },
                position: "absolute",
                left: index === 0 ? "30%" : index === 1 ? "40%" : "50%",
                top: index === 0 ? "30%" : index === 1 ? "25%" : "20%",
                transform: index === 0 ? "rotate(-10deg)" : index === 1 ? "none" : "rotate(10deg)",
                boxShadow: "5px 5px 4px 0px rgba(0, 0, 0, 0.25)",
                transition: "all 0.4s ease-in-out",
                cursor: "pointer"
              })}
            />
          ))}
      </Grid>
    </Grid>
  );
};

export default Intro;
