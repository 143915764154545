import { Theme } from "@mui/material";
import theme from "./default";

export const lightTheme: Theme = {
  ...theme,
  palette: {
    ...theme.palette,
    mode: "light"
  }
};
