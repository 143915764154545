import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { Box } from "@mui/material";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { routes } from "../../constants/routes";
import { CartContext } from "../../context/CartContext";
import { ContentfulContext } from "../../context/ContentfulContext";
import { getButton } from "../../querries/getters";
import { colors } from "../../theme/colors";
import { ButtonSize, ButtonVariant } from "../utils/Button";
import NavButton from "../utils/NavButton";

const CartButton = ({
  mobile,
  setMobileNavOpen
}: {
  mobile?: boolean;
  setMobileNavOpen?: () => void;
}) => {
  const { content } = useContext(ContentfulContext);
  const buttons = content?.buttonsCollection?.items;

  const { cart } = useContext(CartContext);

  const location = useLocation();

  return (
    <Box position="relative">
      {mobile && (
        <NavButton
          onClick={setMobileNavOpen}
          route={routes.cart}
          label={getButton(buttons, "cart")}
          variant={ButtonVariant.secondary}
          size={ButtonSize.full}
        />
      )}

      {!mobile && (
        <NavButton
          route={routes.cart}
          startIcon={<ShoppingCartOutlinedIcon />}
          variant={ButtonVariant.accent}
          sx={
            location.pathname === routes.cart
              ? {
                  color: colors.green,
                  backgroundColor: "transparent",
                  border: `3px solid ${colors.green}`
                }
              : undefined
          }
        />
      )}

      {cart?.length > 0 && (
        <Box
          sx={(theme) => ({
            position: "absolute",
            borderRadius: "100%",
            width: theme.spacing(5),
            height: theme.spacing(5),
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "0.8rem",
            fontWeight: "900",
            top: theme.spacing(-2),
            left: theme.spacing(-2),
            backgroundColor: "red",
            color: "white"
          })}>
          {cart.length}
        </Box>
      )}
    </Box>
  );
};

export default CartButton;
