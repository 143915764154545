import { Box } from "@mui/material";

const GoogleMap = ({ url }: { url: string }) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        borderRadius: 5,
        overflow: "hidden"
      }}>
      <iframe
        title="map"
        src={url}
        width="100%"
        height="400"
        style={{ border: 0 }}
        allowFullScreen={false}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"></iframe>
    </Box>
  );
};

export default GoogleMap;
