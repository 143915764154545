import { Box, Chip, FormControl, MenuItem, Select } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import {
  Control,
  Controller,
  ControllerRenderProps,
  FieldPath,
  FieldValues
} from "react-hook-form";
import { SelectOption } from "../../constants/stock-attributes";

export const ProductFilterSelect = ({
  name,
  control,
  options,
  renderValue,
  children,
  close
}: {
  name: string;
  control: Control<FieldValues, unknown>;
  options?: SelectOption[];
  renderValue?: (
    value: string[],
    field: ControllerRenderProps<FieldValues, FieldPath<FieldValues>>
  ) => ReactNode;
  children?: ReactNode;
  close?: boolean;
}) => {
  const [open, setOpen] = useState(false);

  // If close is triggered, we close the select popup
  useEffect(() => {
    if (close) {
      setOpen(false);
    }
  }, [close]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl sx={{ width: "100%" }}>
          <Select
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            multiple
            renderValue={
              renderValue
                ? (selected) => renderValue(selected, field)
                : (selected) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 0.5
                      }}>
                      {selected?.map((value: string) => (
                        <Chip
                          onMouseDown={(e) => {
                            e.stopPropagation();
                          }}
                          onDelete={() => {
                            const currentValue = field.value as string[];
                            const newValue = currentValue?.filter((v) => v !== value);

                            field.onChange(newValue);
                          }}
                          key={value}
                          label={options?.find((opt) => opt.value === value)?.label}
                        />
                      ))}
                    </Box>
                  )
            }
            id={name}
            variant="outlined"
            {...field}
            value={field?.value ?? []}>
            {children ??
              options?.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
    />
  );
};
