import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const ScrollPositionRestore = () => {
  const location = useLocation();
  const [previousLocation, setPreviousLocation] = useState(location);

  // restore scroll position on navigation
  useEffect(() => {
    if (previousLocation.pathname !== location.pathname) {
      window.scrollTo(0, 0);
      setPreviousLocation(location);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return <></>;
};

export default ScrollPositionRestore;
