/* tslint:disable */
/* eslint-disable */
/**
 * EcoCerram API
 * EcoCerram API generated from BE
 *
 * The version of the OpenAPI document: 0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ContactForm } from "../models";
import { ContactFormToJSON } from "../models";
import * as runtime from "../runtime";

export interface ContactRequest {
  contactForm: ContactForm;
}

/**
 *
 */
export class ContactControllerApi extends runtime.BaseAPI {
  /**
   */
  async contactRaw(
    requestParameters: ContactRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.contactForm === null || requestParameters.contactForm === undefined) {
      throw new runtime.RequiredError(
        "contactForm",
        "Required parameter requestParameters.contactForm was null or undefined when calling contact."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/contact`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ContactFormToJSON(requestParameters.contactForm)
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async contact(
    requestParameters: ContactRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.contactRaw(requestParameters, initOverrides);
  }
}
