export const smoothScroll = (anchor: string) => {
  const element = document.getElementById(anchor);
  if (!element) return;

  const position = element.offsetTop;
  const padding = 100;

  window.scrollTo({
    top: position - padding,
    behavior: "smooth"
  });
};

export const generateId = (length: number) => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const getImagePath = (imageName?: string) => {
  const BASE_PATH = process.env.REACT_APP_API_BASE_PATH;
  return imageName ? `${BASE_PATH}/stock/image/${imageName}` : undefined;
};

export const formatPrice = (price: number) => {
  return price.toLocaleString("sk", {
    style: "currency",
    currency: "EUR"
  });
};

export const formatStock = (amount: number, precision = 2) => {
  const formatted = amount.toLocaleString("sk", {
    style: "decimal",
    maximumFractionDigits: precision
  });

  return formatted;
};

export const roundNumber = (price: number, decimals = 2) => {
  return parseFloat(price.toFixed(decimals));
};

export const getPriceWithTax = (price: number) => {
  const roundedPrice = roundNumber(price);
  return roundedPrice * 1.2;
};
