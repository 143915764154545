import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import { Box, useTheme } from "@mui/material";
import { useState } from "react";
import { StockSizeEnum } from "../../../api";
import { colors } from "../../../theme/colors";
import styles from "./TilePreview.module.css";

type TilePreviewProps = {
  src?: string;
  alt?: string;
  id?: string;
  glossy?: boolean;
  spatial?: boolean;
  size?: StockSizeEnum;
};

const TilePreview = ({ src, alt, id, size, glossy = true, spatial = true }: TilePreviewProps) => {
  const [imageError, setImageError] = useState(false);

  const theme = useTheme();

  const tilePreviewSizeMap: {
    [key: string]: {
      width: { xs: string; md: string };
      height: { xs: string; md: string };
      shadeWidth?: { xs: string; md: string };
      shadeHeight?: { xs: string; md: string };
      shadeBottomOffset?: { xs: string; md: string };
      shadeLeftOffset?: { xs: string; md: string };
    };
  } = {
    // Square sizes

    [StockSizeEnum._3030]: {
      width: { xs: theme.spacing(30), md: theme.spacing(50) },
      height: { xs: theme.spacing(30), md: theme.spacing(50) },
      shadeWidth: { xs: theme.spacing(80), md: theme.spacing(120) },
      shadeHeight: { xs: theme.spacing(20), md: theme.spacing(30) },
      shadeBottomOffset: { xs: theme.spacing(-8), md: theme.spacing(-14) },
      shadeLeftOffset: { xs: theme.spacing(-25), md: theme.spacing(-34) }
    },
    [StockSizeEnum._4040]: {
      width: { xs: theme.spacing(35), md: theme.spacing(55) },
      height: { xs: theme.spacing(35), md: theme.spacing(55) },
      shadeWidth: { xs: theme.spacing(80), md: theme.spacing(120) },
      shadeHeight: { xs: theme.spacing(20), md: theme.spacing(30) },
      shadeBottomOffset: { xs: theme.spacing(-8), md: theme.spacing(-14) },
      shadeLeftOffset: { xs: theme.spacing(-22), md: theme.spacing(-32) }
    },
    [StockSizeEnum._4545]: {
      width: { xs: theme.spacing(40), md: theme.spacing(60) },
      height: { xs: theme.spacing(40), md: theme.spacing(60) },
      shadeWidth: { xs: theme.spacing(80), md: theme.spacing(120) },
      shadeHeight: { xs: theme.spacing(20), md: theme.spacing(30) },
      shadeBottomOffset: { xs: theme.spacing(-8), md: theme.spacing(-14) },
      shadeLeftOffset: { xs: theme.spacing(-20), md: theme.spacing(-30) }
    },
    [StockSizeEnum._5050]: {
      width: { xs: theme.spacing(45), md: theme.spacing(65) },
      height: { xs: theme.spacing(45), md: theme.spacing(65) },
      shadeWidth: { xs: theme.spacing(80), md: theme.spacing(120) },
      shadeHeight: { xs: theme.spacing(20), md: theme.spacing(30) },
      shadeBottomOffset: { xs: theme.spacing(-8), md: theme.spacing(-14) },
      shadeLeftOffset: { xs: theme.spacing(-18), md: theme.spacing(-28) }
    },
    [StockSizeEnum._6060]: {
      width: { xs: theme.spacing(50), md: theme.spacing(70) },
      height: { xs: theme.spacing(50), md: theme.spacing(70) },
      shadeWidth: { xs: theme.spacing(100), md: theme.spacing(140) },
      shadeHeight: { xs: theme.spacing(20), md: theme.spacing(30) },
      shadeBottomOffset: { xs: theme.spacing(-8), md: theme.spacing(-12) },
      shadeLeftOffset: { xs: theme.spacing(-26), md: theme.spacing(-35) }
    },
    [StockSizeEnum._8080]: {
      width: { xs: theme.spacing(55), md: theme.spacing(75) },
      height: { xs: theme.spacing(55), md: theme.spacing(75) },
      shadeWidth: { xs: theme.spacing(100), md: theme.spacing(140) },
      shadeHeight: { xs: theme.spacing(20), md: theme.spacing(35) },
      shadeBottomOffset: { xs: theme.spacing(-8), md: theme.spacing(-14) },
      shadeLeftOffset: { xs: theme.spacing(-22), md: theme.spacing(-35) }
    },
    [StockSizeEnum._120120]: {
      width: { xs: theme.spacing(60), md: theme.spacing(80) },
      height: { xs: theme.spacing(60), md: theme.spacing(80) },
      shadeWidth: { xs: theme.spacing(100), md: theme.spacing(140) },
      shadeHeight: { xs: theme.spacing(20), md: theme.spacing(35) },
      shadeBottomOffset: { xs: theme.spacing(-8), md: theme.spacing(-14) },
      shadeLeftOffset: { xs: theme.spacing(-20), md: theme.spacing(-30) }
    },

    // Rectangle sizes

    [StockSizeEnum._1590]: {
      width: { xs: theme.spacing(50), md: theme.spacing(66) },
      height: { xs: theme.spacing(15), md: theme.spacing(20) },
      shadeWidth: { xs: theme.spacing(80), md: theme.spacing(120) },
      shadeHeight: { xs: theme.spacing(20), md: theme.spacing(30) },
      shadeBottomOffset: { xs: theme.spacing(-8), md: theme.spacing(-14) },
      shadeLeftOffset: { xs: theme.spacing(-16), md: theme.spacing(-28) }
    },
    [StockSizeEnum._2175]: {
      width: { xs: theme.spacing(50), md: theme.spacing(66) },
      height: { xs: theme.spacing(15), md: theme.spacing(20) },
      shadeWidth: { xs: theme.spacing(80), md: theme.spacing(120) },
      shadeHeight: { xs: theme.spacing(20), md: theme.spacing(30) },
      shadeBottomOffset: { xs: theme.spacing(-8), md: theme.spacing(-14) },
      shadeLeftOffset: { xs: theme.spacing(-16), md: theme.spacing(-28) }
    },
    [StockSizeEnum._25575]: {
      width: { xs: theme.spacing(50), md: theme.spacing(66) },
      height: { xs: theme.spacing(15), md: theme.spacing(20) },
      shadeWidth: { xs: theme.spacing(80), md: theme.spacing(120) },
      shadeHeight: { xs: theme.spacing(20), md: theme.spacing(30) },
      shadeBottomOffset: { xs: theme.spacing(-8), md: theme.spacing(-14) },
      shadeLeftOffset: { xs: theme.spacing(-16), md: theme.spacing(-28) }
    },
    [StockSizeEnum._3060]: {
      width: { xs: theme.spacing(30), md: theme.spacing(50) },
      height: { xs: theme.spacing(60), md: theme.spacing(100) },
      shadeWidth: { xs: theme.spacing(80), md: theme.spacing(120) },
      shadeHeight: { xs: theme.spacing(20), md: theme.spacing(30) },
      shadeBottomOffset: { xs: theme.spacing(-8), md: theme.spacing(-14) },
      shadeLeftOffset: { xs: theme.spacing(-25), md: theme.spacing(-34) }
    },
    [StockSizeEnum._39898]: {
      width: { xs: theme.spacing(35), md: theme.spacing(60) },
      height: { xs: theme.spacing(80), md: theme.spacing(140) },
      shadeWidth: { xs: theme.spacing(80), md: theme.spacing(120) },
      shadeHeight: { xs: theme.spacing(20), md: theme.spacing(30) },
      shadeBottomOffset: { xs: theme.spacing(-8), md: theme.spacing(-14) },
      shadeLeftOffset: { xs: theme.spacing(-23), md: theme.spacing(-32) }
    },
    [StockSizeEnum._408283]: {
      width: { xs: theme.spacing(35), md: theme.spacing(60) },
      height: { xs: theme.spacing(80), md: theme.spacing(140) },
      shadeWidth: { xs: theme.spacing(80), md: theme.spacing(120) },
      shadeHeight: { xs: theme.spacing(20), md: theme.spacing(30) },
      shadeBottomOffset: { xs: theme.spacing(-8), md: theme.spacing(-14) },
      shadeLeftOffset: { xs: theme.spacing(-23), md: theme.spacing(-32) }
    },
    [StockSizeEnum._492145]: {
      width: { xs: theme.spacing(35), md: theme.spacing(60) },
      height: { xs: theme.spacing(80), md: theme.spacing(140) },
      shadeWidth: { xs: theme.spacing(80), md: theme.spacing(120) },
      shadeHeight: { xs: theme.spacing(20), md: theme.spacing(30) },
      shadeBottomOffset: { xs: theme.spacing(-8), md: theme.spacing(-14) },
      shadeLeftOffset: { xs: theme.spacing(-23), md: theme.spacing(-32) }
    },
    [StockSizeEnum._60120]: {
      width: { xs: theme.spacing(40), md: theme.spacing(60) },
      height: { xs: theme.spacing(80), md: theme.spacing(120) },
      shadeWidth: { xs: theme.spacing(80), md: theme.spacing(120) },
      shadeHeight: { xs: theme.spacing(20), md: theme.spacing(35) },
      shadeBottomOffset: { xs: theme.spacing(-8), md: theme.spacing(-16) },
      shadeLeftOffset: { xs: theme.spacing(-20), md: theme.spacing(-30) }
    },
    [StockSizeEnum._7530]: {
      width: { xs: theme.spacing(45), md: theme.spacing(65) },
      height: { xs: theme.spacing(20), md: theme.spacing(30) },
      shadeWidth: { xs: theme.spacing(80), md: theme.spacing(120) },
      shadeHeight: { xs: theme.spacing(20), md: theme.spacing(35) },
      shadeBottomOffset: { xs: theme.spacing(-8), md: theme.spacing(-14) },
      shadeLeftOffset: { xs: theme.spacing(-18), md: theme.spacing(-28) }
    },
    [StockSizeEnum._80160]: {
      width: { xs: theme.spacing(45), md: theme.spacing(65) },
      height: { xs: theme.spacing(90), md: theme.spacing(130) },
      shadeWidth: { xs: theme.spacing(80), md: theme.spacing(120) },
      shadeHeight: { xs: theme.spacing(20), md: theme.spacing(35) },
      shadeBottomOffset: { xs: theme.spacing(-8), md: theme.spacing(-16) },
      shadeLeftOffset: { xs: theme.spacing(-18), md: theme.spacing(-28) }
    },
    [StockSizeEnum._100200]: {
      width: { xs: theme.spacing(50), md: theme.spacing(70) },
      height: { xs: theme.spacing(100), md: theme.spacing(140) },
      shadeWidth: { xs: theme.spacing(100), md: theme.spacing(140) },
      shadeHeight: { xs: theme.spacing(20), md: theme.spacing(35) },
      shadeBottomOffset: { xs: theme.spacing(-8), md: theme.spacing(-16) },
      shadeLeftOffset: { xs: theme.spacing(-26), md: theme.spacing(-35) }
    }
  };

  const defaultWidth = { xs: theme.spacing(40), md: theme.spacing(50) };
  const defaultHeight = "auto";

  const defaultShadeWidth = { xs: theme.spacing(70), md: theme.spacing(90) };
  const defaultShadeHeight = { xs: theme.spacing(20), md: theme.spacing(30) };
  const defaultShadeBottomOffset = {
    xs: theme.spacing(-8),
    md: theme.spacing(-12)
  };
  const defaultShadeLeftOffset = {
    xs: theme.spacing(-15),
    md: theme.spacing(-20)
  };

  const applySize = size && spatial;

  return (
    <Box position="relative">
      {src && !imageError ? (
        <Box
          component={"img"}
          onError={() => setImageError(true)}
          id={id}
          src={src}
          alt={alt}
          className={`${spatial && styles.tile} ${glossy && styles.glossy}`}
          sx={{
            position: "relative",
            zIndex: 2,
            width: applySize ? tilePreviewSizeMap[size]?.width || defaultWidth : defaultWidth,
            height: applySize ? tilePreviewSizeMap[size]?.height || defaultHeight : defaultHeight,
            ...(!spatial && {
              transition: "all 0.5s ease-in-out",
              "&:hover": {
                transform: "scale(1.3)"
              }
            })
          }}
        />
      ) : (
        <Box
          sx={(theme) => ({
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: { xs: 3, md: 5 },
            mb: 10,
            width: { xs: theme.spacing(40), md: theme.spacing(50) },
            height: { xs: theme.spacing(40), md: theme.spacing(50) }
          })}>
          <ImageNotSupportedIcon
            sx={(theme) => ({
              fontSize: { xs: theme.spacing(5), md: theme.spacing(10) },
              color: colors.brown
            })}
          />
        </Box>
      )}
      <Box
        sx={{
          position: "absolute",
          width: applySize
            ? tilePreviewSizeMap[size]?.shadeWidth || defaultShadeWidth
            : defaultShadeWidth,
          height: applySize
            ? tilePreviewSizeMap[size]?.shadeHeight || defaultShadeHeight
            : defaultShadeHeight,
          bottom: applySize
            ? tilePreviewSizeMap[size]?.shadeBottomOffset || defaultShadeBottomOffset
            : defaultShadeBottomOffset,
          left: applySize
            ? tilePreviewSizeMap[size]?.shadeLeftOffset || defaultShadeLeftOffset
            : defaultShadeLeftOffset,
          borderRadius: "43.75rem",
          background:
            "radial-gradient(49.74% 49.74% at 50.00% 50.00%, rgb(130 130 130 / 75%) 0%, rgba(172, 172, 172, 0.00) 95%)"
        }}
      />
    </Box>
  );
};

export default TilePreview;
