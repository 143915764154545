import { Box, Paper } from "@mui/material";
import { useContext } from "react";
import { ContentfulContext } from "../../context/ContentfulContext";
import { About as AboutModel, Asset, Maybe } from "../../models/schema";

const ImportantPartners = ({ wrapper = true }: { wrapper?: boolean }) => {
  const { content } = useContext(ContentfulContext);

  const { logosCollection } = content?.home?.about as AboutModel;

  const Content = () => (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: { xs: "column", lg: "row" },
        justifyContent: { xs: "center", lg: "space-between" },
        alignItems: "center",
        p: 4
      }}>
      {logosCollection?.items?.map((logo: Maybe<Asset>, index: number) => (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }} key={index}>
          <Box
            component={"img"}
            src={logo?.url as string}
            alt={logo?.title as string}
            sx={(theme) => ({
              my: { xs: 3, lg: 0 },
              mx: { xs: 0, lg: 3 },
              height: { xs: "auto", lg: theme.spacing(10) },
              width: "auto",
              maxWidth: { xs: theme.spacing(40), md: "none" },
              transition: "all 0.3s ease-in-out",
              filter: "grayscale(100%)",
              "&:hover": {
                filter: "grayscale(0%)"
              }
            })}
          />
        </Box>
      ))}
    </Box>
  );

  return wrapper ? (
    <Paper
      sx={{
        width: "100%"
      }}>
      <Content />
    </Paper>
  ) : (
    <Content />
  );
};

export default ImportantPartners;
