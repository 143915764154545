export const routes = {
  home: "/",
  ean: ":ean",
  products: "/products",
  contact: "/contact",
  partner: "/partner",
  login: "/login",
  cart: "/cart",
  passwordChange: "/password-change",
  passwordReset: "/password-reset"
};

export const Anchors = {
  news: "news",
  aboutUs: "about-us",
  showroom: "showroom"
};
