import { Box } from "@mui/material";
import { useContext, useEffect, useMemo } from "react";
import { StockEditionsEnum } from "../api";
import AboutUs from "../components/view/AboutUs";
import Intro from "../components/view/Intro";
import Showroom from "../components/view/Showroom";
import SpecialProductsSelection from "../components/view/SpecialProductsSelection";
import { ContentfulContext } from "../context/ContentfulContext";
import { ProductsContext } from "../context/ProductsContext";
import { Home } from "../models/schema";

const HomePage = () => {
  const { content } = useContext(ContentfulContext);
  const { products, loading, reloadProducts } = useContext(ProductsContext);

  // When the products page is loaded, reload the products
  useEffect(() => {
    reloadProducts();
  }, []);

  const { newProducts: newProductsLabel, preparingProducts: preparingProductsLabel } =
    content?.home as Home;

  const newProducts = useMemo(
    () => products?.filter((product) => product.editions?.includes(StockEditionsEnum.New)),
    [products]
  );

  const preparingProducts = useMemo(
    () => products?.filter((product) => product.editions?.includes(StockEditionsEnum.Preparing)),
    [products]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        mb: 5,
        "& > :not(template) ~ :not(template)": {
          mt: 10
        }
      }}>
      <Intro />
      <SpecialProductsSelection
        title={newProductsLabel as string}
        products={newProducts}
        loading={loading}
      />
      <SpecialProductsSelection
        title={preparingProductsLabel as string}
        products={preparingProducts}
        loading={loading}
      />
      <AboutUs />
      <Showroom />
    </Box>
  );
};

export default HomePage;
