import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import { Box, Grid, Paper, Typography, useTheme } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { StockSizeEnum, StockThicknessEnum, StockUnitEnum } from "../../api";
import { routes } from "../../constants/routes";
import { sizeOptionsMap, thicknessOptionsMap } from "../../constants/stock-attributes";
import { CartContext } from "../../context/CartContext";
import { ContentfulContext } from "../../context/ContentfulContext";
import { ProductsContext } from "../../context/ProductsContext";
import { Cart, Product } from "../../models/schema";
import { getButton, getValidationMsg } from "../../querries/getters";
import { colors } from "../../theme/colors";
import {
  formatPrice,
  formatStock,
  getImagePath,
  getPriceWithTax,
  roundNumber
} from "../../utils/utilities";
import Button, { ButtonVariant } from "../utils/Button";
import ButtonContainer from "../utils/ButtonContainer";
import Input from "../utils/Input";
import LoadingSpinner from "../utils/LoadingSpinner";

export const CartItem = ({ ean }: { ean?: string }) => {
  const { content } = useContext(ContentfulContext);
  const {
    price,
    piece,
    quantityMeters,
    quantityPieces,
    totalMeters,
    totalPieces,
    packagesCount,
    priceSum,
    withTax,
    withoutTax,
    removeItem: removeItemLabel
  } = content?.cart as Cart;

  const { meterUnit, pieceUnit, pieceUnit2, pieceUnit3 } = content?.product as Product;

  const buttons = content?.buttonsCollection?.items;
  const validations = content?.validationsCollection?.items;

  const { cart, updateItem, removeItem } = useContext(CartContext);
  const [open, setOpen] = useState(false);
  const [initialPatchComplete, setInitialPatchComplete] = useState(false);
  const [packageCount, setPackageCount] = useState(0);
  const theme = useTheme();

  const { products, loading } = useContext(ProductsContext);
  const product = useMemo(() => products?.find((product) => product.ean === ean), [products, ean]);

  const item = cart?.find((item) => item.ean === ean);

  const [imageError, setImageError] = useState(false);

  type Form = {
    amount: string;
  };

  const controls: Form = {
    amount: "amount"
  };

  const {
    control,
    getValues,
    setValue,
    formState: { errors }
  } = useForm();

  useEffect(() => {
    if (item?.quantity) {
      setPackageCount(item.quantity);
    }
  }, [item, cart, product]);

  useEffect(() => {
    if (item?.quantity && product?.amountPerPackage && !initialPatchComplete) {
      setValue(controls.amount, roundNumber(item.quantity * product.amountPerPackage));
      setInitialPatchComplete(true);
    }
  }, [product, item]);

  if (
    !product ||
    isNaN(Number(product.price)) ||
    isNaN(Number(product.packagesOnStock)) ||
    isNaN(Number(product.amountPerPackage))
  )
    return loading ? <LoadingSpinner /> : <></>;

  const pricePerPackage = product?.pricePerPackage ?? 0;
  const pricePerPackageWithTax = getPriceWithTax(pricePerPackage);

  const totalSum = packageCount * pricePerPackage;
  const totalSumWithTax = getPriceWithTax(totalSum);

  const totalUnits = roundNumber(packageCount * (product.amountPerPackage ?? 0));
  const unit =
    product.unit === StockUnitEnum.Meter
      ? meterUnit
      : totalUnits === 1
      ? pieceUnit
      : totalUnits >= 2 && totalUnits < 5
      ? pieceUnit2
      : pieceUnit3;

  const packagesOnStock = Math.floor(product?.packagesOnStock ?? 0);

  const updatePackageCount = (value: number) => {
    setPackageCount(value);
    updateItem({
      ean,
      quantity: value
    });
  };

  const removeItemFromCart = () => {
    if (ean) {
      removeItem(ean);
    }
  };

  const handleDialogReject = () => {
    setOpen(false);

    const { amount } = getValues();

    if (product?.amountPerPackage && item?.quantity && amount === 0) {
      setValue(controls.amount, roundNumber(item.quantity * product.amountPerPackage));
    }
  };

  const productRoute = `${routes.products}/${product.ean}`;
  const tileImagePath = product?.tileFaceImages?.[0];

  return (
    <>
      <Paper
        sx={{
          width: "100%",
          position: "relative",
          p: { xs: 4, md: 8 },
          pb: { xs: 20, md: 8 },
          mb: { xs: 4, md: 8 }
        }}>
        <Typography variant="h5" fontWeight="bold" textAlign="center" mb={{ xs: 6, md: 10 }}>
          {product.name}, {sizeOptionsMap[product.size as StockSizeEnum]},{" "}
          {thicknessOptionsMap[product.thickness as StockThicknessEnum]}
        </Typography>
        <Grid container spacing={{ xs: 4, md: 8 }} component={"form"}>
          <Grid item xs={12} md={3} lg={2} mb={3}>
            <Link
              to={productRoute}
              style={{
                textDecoration: "none",
                width: "100%",
                display: "flex",
                justifyContent: "center"
              }}>
              {tileImagePath && !imageError ? (
                <Box
                  component="img"
                  onError={() => setImageError(true)}
                  src={getImagePath(tileImagePath)}
                  sx={(theme) => ({
                    mt: { xs: 5, md: 0 },
                    mx: { xs: "auto", md: 0 },
                    width: "auto",
                    height: "100%",
                    maxWidth: "100%",
                    maxHeight: { xs: theme.spacing(80), md: "none" }
                  })}
                />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    p: 5,
                    mr: { xs: 0, md: 5 }
                  }}>
                  <ImageNotSupportedIcon
                    sx={(theme) => ({
                      fontSize: { xs: theme.spacing(5), md: theme.spacing(10) },
                      color: colors.brown
                    })}
                  />
                </Box>
              )}
            </Link>
          </Grid>

          <Grid item xs={12} md={6} lg={7} mb={3}>
            <Box
              sx={(theme) => ({
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: "100%",
                maxWidth: theme.spacing(100),
                mx: { md: "auto" }
              })}>
              <Box sx={{ mb: 3, flexGrow: 1 }}>
                <Input
                  widthAuto
                  id={controls.amount}
                  label={
                    product.unit === StockUnitEnum.Meter
                      ? (quantityMeters as string)
                      : (quantityPieces as string)
                  }
                  type="number"
                  error={!!errors.amount}
                  helperText={errors.amount && (errors.amount.message as string)}
                  name={controls.amount}
                  onChange={() => {
                    const { amount } = getValues();

                    if (!packagesOnStock || !product.amountPerPackage) {
                      updatePackageCount(0);
                      return;
                    }

                    if (
                      amount > 0 &&
                      amount <= (packagesOnStock as number) * (product.amountPerPackage as number)
                    ) {
                      const calculated = Math.ceil(amount / product.amountPerPackage);
                      updatePackageCount(calculated);
                      return;
                    }

                    if (amount <= 0) {
                      setValue(controls.amount, 0);
                      setOpen(true);
                      return;
                    }
                  }}
                  rules={{
                    required: getValidationMsg(validations, "required"),
                    min: {
                      value: 0,
                      message: getValidationMsg(validations, "positive")
                    },
                    max: {
                      value: (packagesOnStock as number) * (product.amountPerPackage as number),
                      message: `${getValidationMsg(validations, "max")} ${
                        (packagesOnStock as number) * (product.amountPerPackage as number)
                      }`
                    }
                  }}
                  control={control}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  width: "100%",
                  justifyContent: "space-between",
                  mb: 3
                }}>
                <Typography variant="body2">
                  {product.unit === StockUnitEnum.Meter
                    ? (totalMeters as string)
                    : (totalPieces as string)}
                  :
                </Typography>
                <Typography variant="body2" fontWeight="bold">
                  {formatStock(totalUnits)} {unit}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  width: "100%",
                  mb: 3,
                  justifyContent: "space-between"
                }}>
                <Typography variant="body2">{packagesCount}:</Typography>
                <Typography variant="body2" fontWeight="bold" mr={1}>
                  {packageCount} {piece}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  width: "100%",
                  justifyContent: "space-between",
                  mb: 3
                }}>
                <Typography variant="body2">
                  {price} {withoutTax}:
                </Typography>
                <Typography variant="body2" fontWeight="bold">
                  {formatPrice(pricePerPackage ?? 0)}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  width: "100%",
                  justifyContent: "space-between"
                }}>
                <Typography variant="body2">
                  {price} {withTax}:
                </Typography>
                <Typography variant="body2" fontWeight="bold">
                  {formatPrice(pricePerPackageWithTax ?? 0)}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={3} mb={3}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%"
              }}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  flexGrow: 1,
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  alignItems: { xs: "flex-start", md: "flex-end" }
                }}>
                <Typography variant="body2" mb={{ xs: 1, md: 3 }}>
                  {priceSum}
                </Typography>
                <Typography variant="h5" fontWeight="bold" mb={2}>
                  {formatPrice(totalSum)} ({withoutTax})
                </Typography>
                <Typography variant="h5" fontWeight={100}>
                  {formatPrice(totalSumWithTax)} ({withTax})
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{
            position: "absolute",
            right: 0,
            width: { xs: "100%", md: "auto" },
            top: { xs: "auto", md: "10%" },
            bottom: { xs: 0, md: "auto" },
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}>
          <Paper
            elevation={5}
            sx={(theme) => ({
              p: 3,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "white",
              height: theme.spacing(18),
              width: theme.spacing(18),
              borderBottomLeftRadius: { xs: 0, md: theme.spacing(4) },
              borderTopRightRadius: { xs: "auto", md: 0 },
              borderBottomRightRadius: 0,
              boxShadow: "-5px 0px 5px -1px rgba(0,0,0,0.2), 0px 1px 14px 0px rgba(0,0,0,0.12)"
            })}>
            <Button
              sx={{
                color: colors.statusErrorText,
                borderColor: colors.statusErrorText
              }}
              onClick={() => setOpen(true)}
              startIcon={<DeleteOutlinedIcon />}
              variant={ButtonVariant.secondary}
            />
          </Paper>
        </Box>
      </Paper>

      <Dialog open={open} onClose={handleDialogReject}>
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 4
          }}>
          <DialogTitle>{removeItemLabel}</DialogTitle>
          <DialogActions>
            <ButtonContainer sx={{ mt: 6 }}>
              <Button
                onClick={handleDialogReject}
                label={getButton(buttons, "no")}
                variant={ButtonVariant.secondary}
                sx={{
                  width: theme.spacing(40)
                }}
              />
              <Button
                onClick={removeItemFromCart}
                label={getButton(buttons, "yes")}
                variant={ButtonVariant.primary}
                sx={{
                  width: theme.spacing(40)
                }}
              />
            </ButtonContainer>
          </DialogActions>
        </Paper>
      </Dialog>
    </>
  );
};

export default CartItem;
