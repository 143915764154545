/* tslint:disable */
/* eslint-disable */
/**
 * EcoCerram API
 * EcoCerram API generated from BE
 *
 * The version of the OpenAPI document: 0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from "../runtime";
/**
 *
 * @export
 * @interface AlternativeSize
 */
export interface AlternativeSize {
  /**
   *
   * @type {string}
   * @memberof AlternativeSize
   */
  size?: AlternativeSizeSizeEnum;
  /**
   *
   * @type {string}
   * @memberof AlternativeSize
   */
  ean?: string;
}

/**
 * @export
 */
export const AlternativeSizeSizeEnum = {
  _3060: "DIMENSION_30_60",
  _6060: "DIMENSION_60_60",
  _60120: "DIMENSION_60_120",
  _3030: "DIMENSION_30_30",
  _120120: "DIMENSION_120_120",
  _8080: "DIMENSION_80_80",
  _80160: "DIMENSION_80_160",
  _100200: "DIMENSION_100_200",
  _7530: "DIMENSION_7_5_30",
  _5050: "DIMENSION_50_50",
  _1590: "DIMENSION_15_90",
  _4545: "DIMENSION_45_45",
  _4040: "DIMENSION_40_40",
  _408283: "DIMENSION_40_8_28_3",
  _39898: "DIMENSION_39_8_9_8",
  _2175: "DIMENSION_21_7_5",
  _25575: "DIMENSION_25_5_7_5",
  _492145: "DIMENSION_49_2_14_5"
} as const;
export type AlternativeSizeSizeEnum =
  (typeof AlternativeSizeSizeEnum)[keyof typeof AlternativeSizeSizeEnum];

/**
 * Check if a given object implements the AlternativeSize interface.
 */
export function instanceOfAlternativeSize(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function AlternativeSizeFromJSON(json: any): AlternativeSize {
  return AlternativeSizeFromJSONTyped(json, false);
}

export function AlternativeSizeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AlternativeSize {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    size: !exists(json, "size") ? undefined : json["size"],
    ean: !exists(json, "ean") ? undefined : json["ean"]
  };
}

export function AlternativeSizeToJSON(value?: AlternativeSize | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    size: value.size,
    ean: value.ean
  };
}
