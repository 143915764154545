import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Box, Paper, Typography } from "@mui/material";
import { FC, PropsWithChildren } from "react";
import { colors } from "../../theme/colors";

export type OperationResultType = "SUCCESS" | "ERROR" | null;

type OperationResultProps = {
  result: OperationResultType;
  successTitle?: string;
  errorTitle?: string;
};

const OperationResult: FC<PropsWithChildren<OperationResultProps>> = ({
  successTitle,
  errorTitle,
  result,
  children
}) => {
  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: 4,
        backgroundColor:
          result === "SUCCESS"
            ? colors.statusSuccessBackground
            : result === "ERROR"
            ? colors.statusErrorBackground
            : "default"
      }}>
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        {result === "ERROR" && (
          <ErrorOutlineOutlinedIcon
            sx={(theme) => ({
              color: colors.statusErrorText,
              width: { xs: theme.spacing(20), md: theme.spacing(20) },
              height: { xs: theme.spacing(20), md: theme.spacing(20) },
              mb: 8
            })}
          />
        )}
        {result === "SUCCESS" && (
          <CheckCircleOutlineOutlinedIcon
            sx={(theme) => ({
              color: colors.statusSuccessText,
              width: { xs: theme.spacing(20), md: theme.spacing(20) },
              height: { xs: theme.spacing(20), md: theme.spacing(20) },
              mb: 8
            })}
          />
        )}

        <Typography variant="h5" fontWeight="bold" textAlign="center" mb={5}>
          {result === "SUCCESS" && successTitle}
          {result === "ERROR" && errorTitle}
        </Typography>

        {children}
      </Box>
    </Paper>
  );
};

export default OperationResult;
