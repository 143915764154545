/* tslint:disable */
/* eslint-disable */
/**
 * EcoCerram API
 * EcoCerram API generated from BE
 *
 * The version of the OpenAPI document: 0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from "../runtime";
/**
 *
 * @export
 * @interface ContactForm
 */
export interface ContactForm {
  /**
   *
   * @type {string}
   * @memberof ContactForm
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ContactForm
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof ContactForm
   */
  question?: string;
  /**
   *
   * @type {string}
   * @memberof ContactForm
   */
  recaptchaToken?: string;
}

/**
 * Check if a given object implements the ContactForm interface.
 */
export function instanceOfContactForm(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function ContactFormFromJSON(json: any): ContactForm {
  return ContactFormFromJSONTyped(json, false);
}

export function ContactFormFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactForm {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, "name") ? undefined : json["name"],
    email: !exists(json, "email") ? undefined : json["email"],
    question: !exists(json, "question") ? undefined : json["question"],
    recaptchaToken: !exists(json, "recaptchaToken") ? undefined : json["recaptchaToken"]
  };
}

export function ContactFormToJSON(value?: ContactForm | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    email: value.email,
    question: value.question,
    recaptchaToken: value.recaptchaToken
  };
}
