import { gql } from "@apollo/client";

export const GET_LANGUAGES = gql`
  {
    languageCollection {
      items {
        name
        code
        active
        flag
      }
    }
  }
`;

export const GET_LANGUAGE_LOGO_AND_COLLECTIONS = gql`
  query ($code: String!) {
    languageCollection(where: { code: $code }, limit: 1) {
      items {
        logo {
          title
          description
          contentType
          fileName
          size
          url
          width
          height
        }
        imageError {
          title
          description
          contentType
          fileName
          size
          url
          width
          height
        }
        buttonsCollection {
          items {
            id
            label
          }
        }
        validationErrorMessage
        validationsCollection {
          items {
            type
            message
          }
        }
        typeAttributesCollection {
          items {
            id
            label
          }
        }
        editionAttributesCollection {
          items {
            id
            label
          }
        }
        technicalAttributesCollection {
          items {
            id
            label
          }
        }
        surfaceAttributesCollection {
          items {
            id
            label
          }
        }
        designAttributesCollection {
          items {
            id
            label
          }
        }
        colorAttributesCollection {
          items {
            id
            label
          }
        }
        cookies {
          title
          description {
            json
          }
          allowNeccessary
          allowNeccessaryAlert {
            json
          }
          allowAll
          close
        }
        errorMessage
      }
    }
  }
`;

export const GET_LANGUAGE_GENERAL = gql`
  query ($code: String!) {
    languageCollection(where: { code: $code }, limit: 1) {
      items {
        account {
          loginTitle
          loginDescription {
            json
          }
          loginFailure
          changePasswordTitle
          changePasswordDescription {
            json
          }
          changePasswordSuccess
          changePasswordFailure
          forgotPasswordTitle
          forgotPasswordDescription {
            json
          }
          forgotPasswordSuccess
          forgotPasswordFailure
          username
          password
          oldPassword
          newPassword
          newPasswordAgain
        }
        becomePartner {
          title
          titleUser
          description {
            json
          }
          descriptionUser {
            json
          }
          generalData
          name
          field
          fieldOptions
          reason
          contactData
          email
          phone
          billingData
          ico
          dic
          icdph
          street
          city
          zip
          country
          consentPersonalData
          successMessage
          errorMessage
          interestedTitle
          interestedDescription {
            json
          }
          discountTitle
          discountDescription {
            json
          }
          silverTitle
          silverDiscount
          silverDescription {
            json
          }
          goldTitle
          goldDiscount
          goldDescription {
            json
          }
          diamondTitle
          diamondDiscount
          diamondDescription {
            json
          }
          eliteTitle
          eliteDiscount
          eliteDescription {
            json
          }
        }
        header {
          navigation
          language
          account
          cart
        }
        cart {
          title
          empty {
            json
          }
          emptyLoggedIn {
            json
          }
          dialogTitle
          quantityMeters
          quantityPieces
          totalMeters
          totalPieces
          packagesCount
          priceSum
          size
          price
          piece
          removeItem
          totalSum
          totalSumWithTax
          withoutTax
          withTax
          itemNoStock
          addedToCart
          consentTerms {
            json
            links {
              assets {
                hyperlink {
                  title
                  fileName
                  url
                }
              }
            }
          }
          consentTermsEnabled
          successMessage
          errorMessage
        }
        footer {
          facebook
          facebookLogo {
            title
            description
            contentType
            fileName
            size
            url
            width
            height
          }
          instagram
          instagramLogo {
            title
            description
            contentType
            fileName
            size
            url
            width
            height
          }
          sections
          navigation
          followUs
          disclaimer
        }
      }
    }
  }
`;

export const GET_LANGUAGE_PAGES = gql`
  query ($code: String!) {
    languageCollection(where: { code: $code }, limit: 1) {
      items {
        home {
          intro {
            title {
              json
            }
            subtitle {
              json
            }
            description {
              json
            }
            imagesCollection {
              items {
                title
                description
                contentType
                fileName
                size
                url
                width
                height
              }
            }
          }
          newProducts
          preparingProducts
          about {
            title
            categoriesCollection {
              items {
                amount
                title {
                  json
                }
                description {
                  json
                }
                image {
                  title
                  description
                  contentType
                  fileName
                  size
                  url
                  width
                  height
                }
              }
            }
            logosCollection {
              items {
                title
                description
                contentType
                fileName
                size
                url
                width
                height
              }
            }
          }
          partnersCollection {
            items {
              name
              image {
                title
                description
                contentType
                fileName
                size
                url
                width
                height
              }
            }
          }
          showroom {
            title
            description {
              json
            }
            photosCollection {
              items {
                title
                description
                contentType
                fileName
                size
                url
                width
                height
              }
            }
          }
          warehouse {
            title
            description {
              json
            }
            photosCollection {
              items {
                title
                description
                contentType
                fileName
                size
                url
                width
                height
              }
            }
          }
        }
        product {
          availableSizes
          availableFaces
          tileAttributes
          package
          package2
          package3
          defaultPrice
          available
          alternativeProducts
          seriesProducts
          meterUnit
          pieceUnit
          pieceUnit2
          pieceUnit3
          palette
          partnerMessage {
            json
          }
          notAvailableMessage {
            json
          }
          priceMessage {
            json
          }
          priceDiscountButton
          stockMessage {
            json
          }
          discontinuedAvailableMessage {
            json
          }
          discontinuedWarningMessage {
            json
          }
        }
        products {
          title
          description {
            json
          }
          filterTitle
          size
          thickness
          surface
          design
          color
          type
          edition
          technical
          name
          price
          stock
          preparing
          noStock
          reset
          expand
          collapse
          notSet
          noItems {
            json
          }
        }
        contact {
          title
          description {
            json
          }
          phone
          email
          contactData
          billingData
          bankData
          contactUs
          name
          question
          consentPersonalData
          successMessage
          errorMessage
          phoneValue
          emailValue
          billingDataValue {
            json
          }
          bankDataValue {
            json
          }
          addressTitle
          addressDescription {
            json
          }
          addressUrl
        }
      }
    }
  }
`;
