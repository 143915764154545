import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import FullscreenExitOutlinedIcon from "@mui/icons-material/FullscreenExitOutlined";
import FullscreenOutlinedIcon from "@mui/icons-material/FullscreenOutlined";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import PauseOutlinedIcon from "@mui/icons-material/PauseOutlined";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import { Box, SxProps } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import { ContentfulContext } from "../../context/ContentfulContext";
import { colors } from "../../theme/colors";
import useKeyPress from "../../utils/useKeyPress";
import Button, { ButtonVariant } from "./Button";

const handleFullScreenChange = (isFullscreen: boolean) => {
  const element = document.getElementsByClassName("image-gallery-swipe")[0];

  if (!element) {
    return;
  }

  if (isFullscreen) {
    element.classList.add("image-gallery-fullscreen-swipe");
  } else {
    element.classList.remove("image-gallery-fullscreen-swipe");
  }
};

const galleryControlsStyle: SxProps = {
  position: "absolute",
  zIndex: 4,
  boxShadow: "0px 0px 15px 7px rgba(0, 0, 0, 0.25)"
};

const LeftNav = ({ disabled, onClick }: { disabled: boolean; onClick: any }) => {
  useKeyPress(["ArrowLeft"], () => {
    onClick();
  });

  return (
    <Button
      variant={ButtonVariant.accent}
      startIcon={<ArrowBackOutlinedIcon />}
      disabled={disabled}
      onClick={onClick}
      sx={{
        top: "35%",
        left: 0,
        margin: 5,
        display: { xs: "none", md: "flex" },
        ...galleryControlsStyle
      }}
    />
  );
};

const RightNav = ({ disabled, onClick }: { disabled: boolean; onClick: any }) => {
  useKeyPress(["ArrowRight"], () => {
    onClick();
  });

  return (
    <Button
      variant={ButtonVariant.accent}
      startIcon={<ArrowForwardOutlinedIcon />}
      disabled={disabled}
      onClick={onClick}
      sx={{
        top: "35%",
        right: 0,
        margin: 5,
        display: { xs: "none", md: "flex" },
        ...galleryControlsStyle
      }}
    />
  );
};

const Play = ({ isPlaying, onClick }: { isPlaying: boolean; onClick: any }) => {
  useKeyPress([" "], () => {
    onClick();
  });

  return (
    <Button
      variant={ButtonVariant.accent}
      startIcon={isPlaying ? <PauseOutlinedIcon /> : <PlayArrowOutlinedIcon />}
      onClick={onClick}
      sx={{
        bottom: 0,
        margin: 5,
        display: { xs: "none", md: "flex" },
        ...galleryControlsStyle
      }}
    />
  );
};

const FullScreen = ({
  isFullscreen,
  isMobile,
  onClick,
  setFullScreen
}: {
  isFullscreen: boolean;
  isMobile: boolean;
  onClick: React.MouseEventHandler<HTMLElement>;
  setFullScreen: (f: boolean) => void;
}) => (
  <Button
    variant={ButtonVariant.accent}
    startIcon={isFullscreen ? <FullscreenExitOutlinedIcon /> : <FullscreenOutlinedIcon />}
    onClick={(event) => {
      handleFullScreenChange(!isFullscreen);
      setFullScreen(!isFullscreen);
      onClick(event);
    }}
    sx={{
      bottom: isMobile ? "1.5rem" : 0,
      right: 0,
      margin: 5,
      ...galleryControlsStyle
    }}
  />
);

const ImagePreview = ({
  images,
  autoplay = false,
  showFullscreenButton = true,
  showPlayButton = false,
  maxHeight = "700px",
  maxWidth = "100%",
  sx = {}
}: {
  images: Array<ReactImageGalleryItem>;
  autoplay?: boolean;
  showFullscreenButton?: boolean;
  showPlayButton?: boolean;
  maxHeight?: string;
  maxWidth?: string;
  sx?: SxProps;
}) => {
  const { content } = useContext(ContentfulContext);
  const [fullScreen, setFullScreen] = useState(false);

  const isMobile = window.innerWidth < 600;

  const exitHandler = () => {
    const doc = document as any;
    if (!doc.webkitIsFullScreen && !doc.mozFullScreen && !doc.msFullscreenElement) {
      handleFullScreenChange(false);
      setFullScreen(false);
    }
  };

  useEffect(() => {
    if (document.addEventListener) {
      document.addEventListener("fullscreenchange", exitHandler, false);
      document.addEventListener("mozfullscreenchange", exitHandler, false);
      document.addEventListener("MSFullscreenChange", exitHandler, false);
      document.addEventListener("webkitfullscreenchange", exitHandler, false);
    }
  }, []);

  return images?.length > 0 ? (
    <Box
      sx={{
        width: fullScreen ? "100%" : `${maxWidth} !important`,
        mx: "auto",
        "& img": {
          objectFit: !fullScreen ? "cover !important" : "contain !important",
          maxHeight: fullScreen ? "100vh" : `${maxHeight} !important`,
          aspectRatio: fullScreen ? "unset" : "16/9 !important",
          background: colors.white
        },
        ...sx
      }}>
      <ImageGallery
        items={images}
        onErrorImageURL={content?.imageError?.url as string}
        renderLeftNav={(onClick, disabled) => <LeftNav onClick={onClick} disabled={disabled} />}
        renderRightNav={(onClick, disabled) => <RightNav onClick={onClick} disabled={disabled} />}
        renderPlayPauseButton={(onClick, isPlaying) => (
          <Play onClick={onClick} isPlaying={isPlaying} />
        )}
        renderFullscreenButton={(onClick, isFullscreen) => (
          <FullScreen
            onClick={onClick}
            isFullscreen={isFullscreen}
            isMobile={isMobile}
            setFullScreen={setFullScreen}
          />
        )}
        showFullscreenButton={showFullscreenButton}
        showPlayButton={showPlayButton}
        additionalClass="w-full"
        showThumbnails={false}
        showBullets={images.length > 1}
        autoPlay={!isMobile && autoplay}
        useBrowserFullscreen={true}
      />
    </Box>
  ) : (
    <Box
      sx={(theme) => ({
        width: "100%",
        aspectRatio: "2/1",
        overflow: "hidden",
        background: "white",
        border: `2px solid ${colors.brown}`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        zIndex: 100,
        borderRadius: theme.spacing(4)
      })}>
      <ImageNotSupportedIcon
        sx={(theme) => ({
          fontSize: { xs: theme.spacing(5), md: theme.spacing(10) },
          color: colors.brown
        })}
      />
    </Box>
  );
};

export default ImagePreview;
