import { Box, StandardTextFieldProps, TextField, Typography } from "@mui/material";
import { Control, Controller, FieldValues, RegisterOptions } from "react-hook-form";

const Input = ({
  label,
  sx,
  control,
  rules,
  widthAuto,
  name,
  onChange,
  required,
  fullWidth,
  ...props
}: StandardTextFieldProps & {
  control: Control<FieldValues>;
  name: string;
  rules?: RegisterOptions;
  widthAuto?: boolean;
}) => {
  return (
    <Controller
      defaultValue={""}
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange: onChangeField, ...rest } }) => (
        <Box
          sx={(theme) => ({
            width: widthAuto ? "auto" : "100%",
            maxWidth: fullWidth ? "none" : theme.spacing(100)
          })}
          mb={3}>
          <Typography
            variant="body2"
            mb={1}
            sx={(theme) => ({
              color: props.error ? theme.palette.error.main : theme.palette.text.primary
            })}>
            {label}
            {required && "*"}
          </Typography>
          <TextField
            sx={{ width: "100%", ...sx }}
            onChange={(e) => {
              onChangeField(e);
              onChange && onChange(e);
            }}
            required={required}
            fullWidth
            {...props}
            {...rest}
          />
        </Box>
      )}
    />
  );
};

export default Input;
