import { Box, useTheme } from "@mui/material";
import { useContext } from "react";
import { ContentfulContext } from "../../context/ContentfulContext";

const Logo = ({ variant }: { variant: "header" | "footer" }) => {
  const { content } = useContext(ContentfulContext);
  const logo = content?.logo;
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: { xs: theme.spacing(18), lg: theme.spacing(25) },
        mx: variant === "header" ? 4 : 0
      }}>
      <img
        style={{ width: "inherit" }}
        src={logo?.url as string}
        alt={logo?.description as string}
      />
    </Box>
  );
};

export default Logo;
