import React from "react";
import { Language } from "../models/schema";

export interface ContentfulContextSchema {
  content: Language;
}

export const ContentfulContext = React.createContext<ContentfulContextSchema>(
  {} as ContentfulContextSchema
);
