import { Box, useTheme } from "@mui/material";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { routes } from "../../constants/routes";
import { ContentfulContext } from "../../context/ContentfulContext";
import { getButton } from "../../querries/getters";
import { colors } from "../../theme/colors";
import { ButtonSize, ButtonVariant } from "./Button";
import NavButton from "./NavButton";

const NavigationPanel = ({
  mobile,
  variant = "header",
  setMobileNavOpen
}: {
  mobile?: boolean;
  variant?: "header" | "footer";
  setMobileNavOpen?: () => void;
}) => {
  const location = useLocation();
  const theme = useTheme();

  const { content } = useContext(ContentfulContext);
  const buttons = content?.buttonsCollection?.items;

  const navigationOptions: { route: string; label: string }[] = [
    { route: routes.home, label: "home" },
    { route: routes.products, label: "products" },
    { route: routes.contact, label: "contact" }
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: mobile || variant === "footer" ? "column" : "row",
        "& > :not(template) ~ :not(template)": {
          mt: mobile || variant === "footer" ? 2 : 0,
          ml: mobile || variant === "footer" ? 0 : 2
        }
      }}>
      {navigationOptions.map((option) => (
        <NavButton
          key={option.label}
          onClick={setMobileNavOpen}
          route={option.route}
          label={getButton(buttons, option.label)}
          variant={mobile ? ButtonVariant.secondary : ButtonVariant.accent}
          size={mobile ? ButtonSize.full : ButtonSize.auto}
          isActive={location.pathname === option.route}
          activeStyle={
            mobile
              ? undefined
              : {
                  color: colors.green,
                  backgroundColor: "transparent",
                  border: `3px solid ${colors.green}`
                }
          }
          sx={{
            width: variant === "footer" ? theme.spacing(40) : mobile ? "100%" : "auto"
          }}
        />
      ))}
    </Box>
  );
};

export default NavigationPanel;
