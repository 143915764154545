import { Box } from "@mui/material";
import { CSSProperties, FC } from "react";
import { Asset } from "../../models/schema";
import { colors } from "../../theme/colors";
import Button, { ButtonProps } from "./Button";

type SocialButtonProps = {
  network: "facebook" | "instagram";
  link?: string;
  logo?: Asset;
} & ButtonProps;

const Logo = ({ logo }: { logo?: Asset }) => (
  <Box
    component="img"
    sx={(theme) => ({ width: theme.spacing(4), height: theme.spacing(4) })}
    src={logo?.url as string}
    alt={logo?.description as string}
  />
);

const SocialButton: FC<SocialButtonProps> = ({ network, link, logo, ...props }) => {
  let sx: CSSProperties = {
    width: "100%"
  };

  switch (network) {
    case "facebook":
      sx = {
        ...sx,
        background: `linear-gradient(84deg, ${colors.facebook1} 0%, ${colors.facebook2} 100%)`
      };
      break;
    case "instagram":
      sx = {
        ...sx,
        background: `linear-gradient(84deg, ${colors.instagram1} 0%, ${colors.instagram2} 100%)`
      };
      break;
  }

  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <Button {...props} sx={sx}>
        <Logo logo={logo} />
      </Button>
    </a>
  );
};

export default SocialButton;
