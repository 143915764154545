import {
  Button,
  ColorAttribute,
  DesignAttribute,
  EditionAttribute,
  Maybe,
  Message,
  SurfaceAttribute,
  TechnicalAttribute,
  TypeAttribute,
  Validation
} from "../models/schema";

export const getButton = (buttons: Maybe<Button>[] | undefined, id: string) =>
  buttons?.find((button) => button?.id === id)?.label as string;

export const getMessage = (messages: Maybe<Message>[] | undefined, id: string) =>
  messages?.find((message) => message?.id === id)?.label as string;

export const getValidationMsg = (validations: Maybe<Validation>[] | undefined, type: string) =>
  validations?.find((validation) => validation?.type === type)?.message as string;

export const getTileAttribute = (
  tileAttributes:
    | Maybe<
        | TypeAttribute
        | EditionAttribute
        | TechnicalAttribute
        | SurfaceAttribute
        | DesignAttribute
        | ColorAttribute
      >[]
    | undefined,
  id: string
) => tileAttributes?.find((attribute) => attribute?.id === id)?.label as string;
