import { documentToReactComponents, Options } from "@contentful/rich-text-react-renderer";
import { BLOCKS, Document, INLINES } from "@contentful/rich-text-types";
import { Box, SxProps, Typography } from "@mui/material";
import { ReactNode } from "react";
import { Asset, Scalars } from "../../models/schema";

const RenderContent = ({
  content,
  link,
  options = {},
  sx = {},
  suffix = ""
}: {
  content: Scalars["JSON"];
  link?: Asset;
  options?: Options;
  sx?: SxProps;
  suffix?: ReactNode | string;
}) => {
  const defaultOptions: Options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <Typography variant="h1" textAlign="center">
          {children}
          {suffix}
        </Typography>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <Typography variant="h2" textAlign="center">
          {children}
          {suffix}
        </Typography>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Typography variant="h3" textAlign="center">
          {children}
          {suffix}
        </Typography>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <Typography variant="h4" textAlign="center">
          {children}
          {suffix}
        </Typography>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <Typography variant="h5" textAlign="center">
          {children}
          {suffix}
        </Typography>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <Typography variant="h6" textAlign="center">
          {children}
          {suffix}
        </Typography>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography variant="body2" sx={{ whiteSpace: "pre-wrap" }}>
          {children}
          {suffix}
        </Typography>
      ),
      [INLINES.HYPERLINK]: (node, children) => {
        const data = node.data as any;
        const hostname = window.location.hostname;
        return (
          <a
            href={data.uri}
            target={`${data.uri.startsWith(hostname) ? "_self" : "_blank"}`}
            rel={`${data.uri.startsWith(hostname) ? "" : "noopener noreferrer"}`}>
            {children}
            {suffix}
          </a>
        );
      },
      [INLINES.ASSET_HYPERLINK]: (node, children) =>
        link ? (
          <a href={link?.url as string} target="_blank" rel="noopener noreferrer">
            {children}
            {suffix}
          </a>
        ) : (
          <Typography variant="body2" sx={{ whiteSpace: "pre-wrap" }}>
            {children}
            {suffix}
          </Typography>
        )
    }
  };

  const merged: Options = {
    renderNode: {
      ...defaultOptions.renderNode,
      ...options.renderNode
    },
    renderMark: {
      ...defaultOptions.renderMark,
      ...options.renderMark
    },
    renderText: options.renderText || defaultOptions.renderText
  };

  return (
    <Box sx={sx}>
      {typeof content === "string"
        ? content
        : documentToReactComponents(content as unknown as Document, merged)}
    </Box>
  );
};

export default RenderContent;
